import { images } from "../../../assets/index";

export const ProductsInfomation = {
  products: [
    {
      id: "0",
      routeName: "automaticTicketResolver",
      productName: "Automatic Ticket Resolver",
      productDescription:
        "Assess the quality of tickets, automatically classify the tickets and training of various machine learning algorithms. Additionally, it leverages machine learning to predict, classify and solve new tickets. ATR will also provide productivity and utilisation anlaytics for the support team working on tickets.",
      background: "#72d1e0",
      type: "APPS",
      backgroundImage: images.ATRIcon,
      version: "4.3"
    },
    {
      id: "1",
      routeName: "quasarpp",
      productName: "Quasar++",
      productDescription:
        "GenWizard Quasar++ is a customisable document management and digitisation tool. It leverages the latest Machine Learning (ML) and Optical Character Recognition (OCR) technology to process documents. Quasar++ categorises, extracts and stores information from handwritten and printed documents, and can be configured to share extracted information with integrated systems.",
      background: "#72d1e0",
      type: "APPS",
      backgroundImage: images.Turing,
      version: "2.3"
    },
    {
      id: "2",
      routeName: "adPlatform",
      productName: "AD",
      productDescription:
        "GenWizard AD is an integrated GenAl platform for Application Development and/or System Integration which brings together the end-to-end spectrum of intelligent automation services across the Technology Delivery Life Cycle. This platform helps to transform enterprises and reimagine IT at speed & scale to drive exponential benefits.",
      background: "#72d1e0",
      type: "APPS",
      backgroundImage: images.ADPlatform,
      version: "NA"
    },
    {
      id: "3",
      routeName: "ticketAnalysis",
      productName: "Ticket Analysis",
      productDescription:
        "Ticket Analysis is a Splunk application which gives service management teams outstanding visibility of ticket inflow volumes that drive resources workload, accelerating incident and problem root-cause analysis. It creates new levels of insight by identifying high-severity issues that impact businesses, and provides valuable inputs to drive efficiency improvements, optimize resource structure and identify potential automation oppotunities.",
      background: "#72d1e0",
      type: "APPS",
      backgroundImage: images.TicketAnalyticsIcon,
      version: "4.8.3"
    },
    {
      id: "4",
      routeName: "stormseer",
      productName: "StormSeer",
      productDescription:"Stormseer application predicts outages of critical systems can cause major impacts to business in terms of monetary loss, reduction of loyalty score and overall customer satisfaction. Stormseer averts this situation by pro-actively predicting possible points of failure in the business flow. Self-heal or intervention ahead of time can help avert the adverse impact leading to improvements in overall Business KPIs.",
      background: "#72d1e0",
      type: "APPS",
      backgroundImage: images.StormSeer,
      version: "1.2"
    },
    {
      id: "5",
      routeName: "eventOpsPlatform",
      productName: "EventOps",
      productDescription:
        "EventOps is a cloud-based event management tool designed to accelerate delivery efficiency. It efficiently manages events from various sources through rule and AI-powered event triage, ensuring streamlined operations across complex distributed environments.",
      background: "#72d1e0",
      type: "APPS",
      backgroundImage: images.EventOpsPlatform,
      version: "1.1"
    },
    {
      id: "6",
      routeName: "utkmPlatform",
      productName: "Klewer & Quasar",
      productDescription:
        "Klewer employs Gen AI to accelerate & digitize knowledge transition by creating transcripts and System Understanding Documents from KT recordings. Quasar helps build a digitized knowledge base from various sources & repositories which can then be accessed through a Gen-AI enabled conversational interface.",
      background: "#72d1e0",
      type: "APPS",
      backgroundImage: images.UTKMPlatform,
      version: "1.4"
    },
    {
      id: "7",
      routeName: "genWizardPlatform",
      productName: "GenWizard Platform",
      productDescription:
        "GenWizard is an advanced platform that combines ML, OCR technology, and GenAI to streamline document processing, application development, system integration, and ticket management.",
      background: "#72d1e0",
      type: "PLATFORMS",
      backgroundImage: images.GenWizardPlatform,
      version: "NA"
    },
    {
      id: "8",
      routeName: "dedicatedPlatform",
      productName: "Dedicated Splunk Platform",
      productDescription:
        "Dedicated Platform installs Splunk on our GenWizard ACP secured cloud, this base platform is a pre-request so that applications can be installed on top of it. It gives you the ability to load and visualise your Big Data. You can also build your own applications on top of this platform. This option will install a dedicated Splunk instance.",
      background: "#72d1e0",
      type: "PLATFORMS",
      backgroundImage: images.DedicatedPlatform,
      version: "NA"
    },
    {
      id: "9",
      routeName: "managedPlatform",
      productName: "Managed Splunk Platform",
      productDescription:
        "Managed Platform installs Splunk on our GenWizard ACP secured cloud, this base platform is a pre-request so that GenWizard applications can be installed on top of it. It gives you the ability to load and visualise your Big Data.",
      background: "#72d1e0",
      type: "PLATFORMS",
      backgroundImage: images.ManagedPlatform,
      version: "NA"
    },
    {
      id: "10",
      routeName: "plainInstance",
      productName: "Plain Instance",
      productDescription:
        "Plain Instance Provisioning. By placing order for a plain instance what you get is a plain AWS instance with no splunk installed in it.",
      background: "#72d1e0",
      type: "PLATFORMS",
      backgroundImage: images.PlainInstance,
      version: "NA"
    },
    {
      id: "11",
      routeName: "onPremiseService",
      productName: "On-Premise Service",
      productDescription:
        "The GenWizard can be deployed On-Premise if your client wishes to keep their data local and/or On-premise. GenWizard uses Splunk as its base platform and it will need to be deployed first and then you can install Accenture’s GenWizard applications. You can also build your own dashboards on top of this platform (not applications).",
      background: "#72d1e0",
      type: "Lisence",
      backgroundImage: images.OnPremise,
      version: "NA"
    },
    // {
    //   id: "5",
    //   routeName: "devOpsAnalytics",
    //   productName: "myWizard AiOps DevOps Analytics",
    //   productDescription:
    //     "The myWizard AiOps DevOps Analytics Splunk App is a platform for aggregating, investigating, correlating and interpreting data from across the DevOps continuous delivery chain. Having data from across the tools all in one place and correlating between them which has traditionally been siloed enables the creation of a DevOps data matrix. With these tools in hand the user can identify bottlenecks as they occur, cut IT costs, drastically reduce time to market, and increase throughput of the delivery team.",
    //   background: "#72d1e0",
    //   type: "App",
    //   backgroundImage: images.DevOpsIcon,
    // },
    {
      id: "12",
      routeName: "automationAnywhereLicenses",
      productName: "Automation Anywhere Licenses",
      productDescription:
        "Automation Anywhere Licenses is used to purchase Control Room, Runner and/or Developer Licenses.",
      background: "#72d1e0",
      type: "Lisence",
      backgroundImage: images.AutomationLicense,
      version: "NA"
    },
    
    {
      id: "13",
      routeName: "bundleOne",
      productName: "bundleOne",
      productDescription: "Bundle One: DP+ATR+TA",
      background: "#72d1e0",
      type: "Bundles",
      backgroundImage: "#fff",
    },
    {
      id: "14",
      routeName: "bundleTwo",
      productName: "bundleTwo",
      productDescription: "Bundle Two: MP+TA",
      background: "#72d1e0",
      type: "Bundles",
      backgroundImage: "#fff",
    },
    {
      id: "15",
      routeName: "bundleThree",
      productName: "bundleThree",
      productDescription: "Bundle Three: DP+TA",
      background: "#72d1e0",
      type: "Bundles",
      backgroundImage: "#fff",
    },
    // {
    //   id: "11",
    //   routeName: "sharepoint",
    //   productName: "myWizard AiOps Sharepoint",
    //   productDescription:
    //     "The myWizard AiOps SharePoint AddOn can be used to monitor various SharePoint files by indexing the data into Splunk.",
    //   background: "#72d1e0",
    //   type: "App",
    //   backgroundImage: images.SharepointIcon,
    // },
    // {
    //   id: "10",
    //   routeName: "reportExtractor",
    //   productName: "myWizard AiOps Report Extractor",
    //   productDescription:
    //     "To help clients amaze their audience with appealing, custom, shareable reports built from Splunk dashboard visualisations through a simple, convenient user interface.",
    //   background: "#72d1e0",
    //   type: "App",
    //   backgroundImage: images.ReportExtrator,
    // },
    // {
    //   id: "15",
    //   routeName: "splunkClusterPlatform",
    //   productName: "SplunkCluster Platform",
    //   productDescription:
    //     "To automate and standardize Splunk cluster build, deployment and operation activities, and thereby save time and effort through ready-to-deploy automation and process optimization. A suite of ready-to-deploy automation artefacts for building and managing Splunk cluster environments, thereby saving significant initial build effort in setup.",
    //   background: "#72d1e0",
    //   type: "Instance",
    //   backgroundImage: images.SplunkCluster,
    //   version: "3.6.3"
    // },
    //{
    //   id: "14",
    //   routeName: "stack360",
    //   productName: "myWizard AiOps Stack Application",
    //   productDescription:
    //    "STACK360 is an executive summary tool which aggregates various metrics such as systems, hosts and environments into dashboards that represent various business statuses and KPIs.",
    //   background: "#72d1e0",
    //   type: "App",
    //   backgroundImage: images.Stack360,
    //},
    // {
    //   id: "15",
    //   routeName: "stormwatch",
    //   productName: "myWizard AiOps StormWatch",
    //   productDescription:
    //     "myWizard AiOps StormWatch is a real-time monitoring tool that uses machine learning to intelligently identify and alert on anomalies in complex user-defined systems.",
    //   background: "#72d1e0",
    //   type: "App",
    //   backgroundImage: images.StormWatch,
    // },
    // {
    //   id: "16",
    //   routeName: "windowsProvisioningPlatform",
    //   productName: "Windows Provisioning Platform",
    //   productDescription:
    //     "By placing order you will get a plain windows instances without any app installed in it.",
    //   background: "#72d1e0",
    //   type: "Instance",
    //   backgroundImage: images.WindowsProvisioningPlatform,
    //   version: "NA"
    // },
    
  ],
};
