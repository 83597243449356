import React, { useState } from "react";
import {
  StyledTextField,
  StyledDefaultTextField,
  RadioWrapper,
  StyledFormLabel,
  StyledRadioGroup,
  StyledArcher,
  StyledTooltip,
  StyledFormControl,
  StyledSelect,
  AlertContent,
} from "../FuiStyles";
import { RegexStyledTextField } from "../../../helper/utils";
import {
  MenuItem,
  InputLabel,
  Radio,
  FormControlLabel,
  DialogContent,
  DialogContentText,
  DialogActions,
  Checkbox,
  CircularProgress,
  FormGroup,
  FormControl,
  FormLabel,
  FormHelperText,
  ListItemText,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import CheckCircle from "@material-ui/icons/CheckCircle";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import { longTextDialogFlow, longTextCustom } from "../Constants/LongText";
import {
  longTextDNS,
  longTextName,
  longTextUser,
  longTextUrl,
  longTextNotes,
  longTextLicenses,
  longTextChooseTheExistingInstance,
  longTextRoot,
  alertPartOne,
  alertPartTwo,
  alertPartThree,
  alertPartFour,
  alertPartFive,
  secondaryContactText,
  longTextOpenAiRegion,
} from "../Constants/LongText";
import { CommonDialog } from "../../common/CommonComponents/Dialog";
import {
  Subtitle3,
  Subtitle5,
} from "../../common/CommonStyles/CommonTypographyStyles";
import { PrimaryButton } from "../../common/CommonComponents/Buttons";
import { indexOf } from "lodash";
import { Autocomplete } from "@material-ui/lab";


export function DMSIdField(values, handleChange) {
  return (
    <StyledTooltip title={longTextDNS} placement="left-start">
      <StyledTextField
        key="DMSId"
        label="DMS ID"
        value={values.DMSId}
        id="DMSId"
        margin="dense"
        variant="outlined"
        onChange={(e) => handleChange(e, "DMSId")}
      />
    </StyledTooltip>
  );
}

export function EndToEndUIdField(aSelectedInstance) {
  return (
    <StyledDefaultTextField
      key="EndToEndUId"
      label="myWizard End To End UID"
      value={
        aSelectedInstance.EndToEndUId === ""
          ? aSelectedInstance.DMSId
          : aSelectedInstance.EndToEndUId
      }
      id="EndToEndUId"
      margin="dense"
      variant="outlined"
      readOnly
    />
  );
}

export function DeployRegionField(handleChange, aSelectedInstance) {
  return (
    <StyledDefaultTextField
      key="DeployRegion"
      label="Deploy Region"
      value={
        aSelectedInstance.DeployRegion
          ? aSelectedInstance.DeployRegion
          : "UNKNOWN"
      }
      id="Deploy Region"
      variant="outlined"
      margin="dense"
      onChange={(e) => handleChange(e, "DeployRegion")}
      readOnly
    />
  );
}

export function ClientNameField(handleChange, aSelectedInstance) {
  return (
    <StyledDefaultTextField
      key="ClientName"
      label="Client Name"
      value={
        aSelectedInstance.ClientName
          ? aSelectedInstance.ClientName
          : "GenWizard AiOps"
      }
      id="ClientName"
      variant="outlined"
      margin="dense"
      onChange={(e) => handleChange(e, "ClientName")}
      InputProps={{
        readOnly: true,
      }}
    />
  );
}

export function VendorNameField(vendorName) {
  return (
    <StyledDefaultTextField
      label="Vendor Name"
      id="VendorName"
      variant="outlined"
      margin="dense"
      defaultValue={vendorName}
      InputProps={{
        readOnly: true,
      }}
    />
  );
}

export function SAPContractIDField(handleChange, aSelectedInstance) {
  return (
    <StyledTooltip title={longTextDNS} placement="left-start">
      <StyledDefaultTextField
        key="SAPContractID"
        label="SAP Contract ID"
        value={
          aSelectedInstance.SAPContractID
            ? aSelectedInstance.SAPContractID
            : "UNKNOWN"
        }
        id="SAPContractID"
        variant="outlined"
        margin="dense"
        onChange={(e) => handleChange(e, "SAPContractID")}
        readOnly
      />
    </StyledTooltip>
  );
}

export function PlatformTypeField(handleChange, aSelectedInstance) {
  return (
    <StyledDefaultTextField
      key="AaamPlatformType"
      label="myWizard AiOps Platform Type"
      value={
        aSelectedInstance.AaamPlatformType
          ? aSelectedInstance.AaamPlatformType
          : "UNKNOWN"
      }
      id="AaamPlatformType"
      variant="outlined"
      margin="dense"
      onChange={(e) => handleChange(e, "AaamPlatformType")}
      readOnly
    />
  );
}

export function CustomerEnterpriseIDField(createdBy, config) {
  return (
    <StyledTooltip title={longTextDNS} placement="left-start">
      <StyledDefaultTextField
        // label="Customer Enterprise ID"
        label={config ? "Customer Enterprise ID" : "Provisioned By"}
        id="CustomerEnterpriseID"
        margin="dense"
        variant="outlined"
        defaultValue={createdBy}
        InputProps={{
          readOnly: true,
        }}
      />
    </StyledTooltip>
  );
}

export function ServiceNowTicket(handleChange, values, isServiceNowTicketValid) {
  return (
    <StyledTextField
      // label="Customer Enterprise ID"
      label="CR/RITM Number"
      id="RITM"
      margin="dense"
      variant="outlined"
      value={values.RITM}
      onChange={(e) => handleChange(e, "RITM")}
      helperText={
        values.RITM &&
        !isServiceNowTicketValid &&
        "Please provide a valid RITM ticket Number."
      }
      error={!isServiceNowTicketValid}
    // required={true}
    // InputProps={{
    //   readOnly: true,
    // }}
    />
  );
}

export function WBSECodeField(handleChange, values, isWBSECodeValid, isWBSECodeMDIValid) {
  return (
    <StyledTextField
      label="WBSE Code"
      id="WBSEcode"
      margin="dense"
      variant="outlined"
      value={values.WBSEcode}
      onChange={(e) => handleChange(e, "WBSEcode")}
      helperText={
        (values.WBSEcode &&
        !isWBSECodeValid &&
        "Please provide a valid WBSE Code.") || (values.WBSEcode && !isWBSECodeMDIValid && "Please do not use MD & I WBSE Code.")
      }
      error={!isWBSECodeValid || !isWBSECodeMDIValid}
    />
  );
}

export function AdminDistributionGroupField(handleChange, values) {
  return (
    <StyledTextField
      label="Admin Distribution Group"
      id="AdminDistributionGroup"
      margin="dense"
      variant="outlined"
      value={values.AdminDistributionGroup}
      onChange={(e) => handleChange(e, "AdminDistributionGroup")}
    />
  );
}

export function SuperAdminDistributionGroupFieldQpp(handleChange, values) {
  return (
    <StyledTextField
      label="Super Admin Distribution Group"
      id="Super AdminDistributionGroup"
      margin="dense"
      variant="outlined"
      value={values.SuperAdminDistributionGroup}
      onChange={(e) => handleChange(e, "SuperAdminDistributionGroup")}
    />
  );
}



export function AdminDistributionGroupFieldATR(handleChange, values, error) {
  return (
    <StyledTextField
      label="Admin Distribution Group"
      id="AdminDistributionGroup"
      margin="dense"
      variant="outlined"
      value={values.AdminDistributionGroup}
      onChange={(e) => handleChange(e, "AdminDistributionGroup")}
      helperText={
        error &&
        "Please input only one Admin Distribution Group."
      }
      error={error}
    />
  );
}


export function SecondaryContact(handleChange, values, config) {
  const inputRegex = /[^a-z0-9.]/gi;
  return (
    <StyledTooltip title={secondaryContactText} placement="left-start">
      <RegexStyledTextField
        regex={inputRegex}
        onChange={(e) => handleChange(e, "SecondaryPOC")}
        label={config ? "Secondary POC" : "Client Project Requester Email ID"}
        id="SecondaryPOC"
        margin="dense"
        variant="outlined"
        value={values.SecondaryPOC}
        inputProps={{ maxLength: 50 }}
      />
    </StyledTooltip>
  );
}

export function MobileNumberField(handleChangeMobileNo, values) {
  return (
    <StyledTextField
      label="Customer Mobile Number"
      id="CustomerMobileNo"
      margin="dense"
      variant="outlined"
      value={values.CustomerMobileNo}
      onChange={(e) => handleChangeMobileNo(e, "CustomerMobileNo")}
    />
  );
}

export function UsersField(
  handleChange,
  values
  // , isRequired, userValidations
) {
  //   const isRequiredCondition = isRequired ? true : false;
  return (
    <StyledTooltip title={longTextUser} placement="left-start">
      <StyledTextField
        label="Users"
        id="Users"
        margin="dense"
        variant="outlined"
        value={values.Users}
        onChange={(e) => handleChange(e, "Users")}
      // error={userValidations.emptyString}
      // helperText={
      //   userValidations.emptyString
      //     ? "This field is required"
      //     : undefined
      // }
      // required={isRequiredCondition}
      />
    </StyledTooltip>
  );
}

export function ServiceGroupField(handleChange, values) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-service-group">Service Group</InputLabel>
      <StyledSelect
        value={values.ServiceGroup}
        onChange={(e) => handleChange(e, "ServiceGroup")}
        label="Service Group"
      >
        <MenuItem value="">
          <em>--Select Service Group--</em>
        </MenuItem>
        <MenuItem value="Application Outsourcing">
          Application Outsourcing
        </MenuItem>
        <MenuItem value="Business Process Outsourcing">
          Business Process Outsourcing
        </MenuItem>
        <MenuItem value="Infrastructure Consulting">
          Infrastructure Consulting
        </MenuItem>
        <MenuItem value="Infrastructure Outsourcing">
          Infrastructure Outsourcing
        </MenuItem>
        <MenuItem value="Management Consulting">Management Consulting</MenuItem>
        <MenuItem value="Strategy Consulting">Strategy Consulting</MenuItem>
        <MenuItem value="System Integration">System Integration</MenuItem>
        <MenuItem value="Technology Consulting">Technology Consulting</MenuItem>
      </StyledSelect>
    </StyledFormControl>
  );
}

export function InfrastructureTypeField(handleChange, values) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-service-group">Infrastructure Type</InputLabel>
      <StyledSelect
        value={values.InfrastructureType}
        onChange={(e) => handleChange(e, "InfrastructureType")}
        label="Infrastructure Type"
      >
        <MenuItem value="">
          <em>--Select Infrastructure Type--</em>
        </MenuItem>
        <MenuItem value="Client On-Premise">
          Client On-Premise
        </MenuItem>
        <MenuItem value="Client Cloud">
          Client Cloud
        </MenuItem>
        <MenuItem value="Accenture Cloud">
          Accenture Cloud
        </MenuItem>
        <MenuItem value="Others">
          Others
        </MenuItem>
      </StyledSelect>
    </StyledFormControl>
  );
}

export function LicensePurposeField(handleChange, values) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-service-group">License Purpose</InputLabel>
      <StyledSelect
        value={values.LicensePurpose}
        onChange={(e) => handleChange(e, "LicensePurpose")}
        label="License Purpose"
      >
        <MenuItem value="">
          <em>--Select License Purpose--</em>
        </MenuItem>
        <MenuItem value="AiOps Asset">
          AiOps Asset
        </MenuItem>
        <MenuItem value="Custom">
          Custom
        </MenuItem>
      </StyledSelect>
    </StyledFormControl>
  );
}

// export function AiOpsAssetField(handleChange, values, assets) {
//   return (
//     <StyledFormControl variant="outlined" margin="dense">
//       <InputLabel htmlFor="outlined-service-group">AiOps Asset</InputLabel>
//       <StyledSelect
//         value={values.AiOpsAsset}
//         onChange={(e) => handleChange(e, "AiOpsAsset")}
//         label="AiOps Asset"
//       >
//         <MenuItem value="">
//           <em>--Select AiOps Asset--</em>
//         </MenuItem>
//         {assets.map(item => (
//           <MenuItem key={item} value={item}>{item}</MenuItem>
//         ))}

//       </StyledSelect>
//     </StyledFormControl>
//   );
// }

export function AiOpsAssetField(handleChange, values, assets, selectedAsset) {

  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-service-group">AiOps Asset</InputLabel>
      <StyledSelect
        multiple={true}
        value={values.AiOpsAsset}
        onChange={(e) => handleChange(e, "AiOpsAsset")}
        label="AiOps Asset"
        renderValue={(selected) => selected.join(', ')}
      >
        <MenuItem value="">
          <em>--Select AiOps Asset--</em>
        </MenuItem>
        {assets.map(item => (
          <MenuItem key={item} value={item}>
            {/* {item} */}
            <Checkbox checked={values.AiOpsAsset.indexOf(item) > -1} />
            <ListItemText primary={item} />
          </MenuItem>
        ))}

      </StyledSelect>
    </StyledFormControl>
  );
}

export function CustomField(handleChange, values) {
  return (
    <StyledTooltip title={longTextCustom} placement="left-start">
      <StyledTextField
        label="Custom"
        id="Custom"
        margin="dense"
        variant="outlined"
        value={values.Custom}
        onChange={(e) => handleChange(e, "Custom")}
      />
    </StyledTooltip>
  );
}

export function UsersDistributionGroupField(handleChange, values) {
  return (
    <StyledTextField
      label="Users Distribution Group"
      id="UsersDistributionGroup"
      margin="dense"
      variant="outlined"
      value={values.UsersDistributionGroup}
      onChange={(e) => handleChange(e, "UsersDistributionGroup")}
    />
  );
}

export function DialogFlowField(handleChange, values, isDialogFlow) {
  return (
    <RadioWrapper>
      <StyledFormLabel component="legend">Dialog Flow</StyledFormLabel>
      <StyledRadioGroup
        aria-label="dialog-flow"
        name="DialogFlow"
        value={values.DialogFlow}
        onChange={(e) => handleChange(e, "DialogFlow")}
        row
      >
        <FormControlLabel
          checked={isDialogFlow}
          value="enabled"
          control={<Radio data-cy="DialogFlowEnabled" color="primary" />}
          margin="dense"
          label="Enabled"
        />
        <FormControlLabel
          checked={!isDialogFlow}
          value="disabled"
          margin="dense"
          control={<Radio data-cy="DialogFlowDisabled" color="primary" />}
          label="Disabled"
        />
      </StyledRadioGroup>
    </RadioWrapper>
  );
}

export function DialogFlowDevTokenField(handleChange, values) {
  return (
    <StyledTooltip title={longTextDialogFlow} placement="left-start">
      <StyledTextField
        label="Dialog Flow Dev Token"
        id="DialogFlowDevToken"
        margin="dense"
        variant="outlined"
        onChange={(e) => handleChange(e, "DialogFlowDevToken")}
        value={values.DialogFlowDevToken}
      />
    </StyledTooltip>
  );
}

export function DialogFlowClientTokenField(handleChange, values) {
  return (
    <StyledTooltip title={longTextDialogFlow} placement="left-start">
      <StyledTextField
        label="Dialog Flow Client Token"
        id="DialogFlowClientToken"
        margin="dense"
        variant="outlined"
        onChange={(e) => handleChange(e, "DialogFlowClientToken")}
        value={values.DialogFlowClientToken}
      />
    </StyledTooltip>
  );
}


export function OpenAIField(handleChange, values, isOpenAI) {
  return (
    <div>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={isOpenAI} />}
          label="Enable OpenAI"
          name="OpenAI"
          onChange={(e) => handleChange(e, "OpenAI")}
        />
      </FormGroup>
    </div>
  );
}


export function OpenAIFieldATR(handleChange, values, isOpenAI) {
  return (
    <div>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={isOpenAI} />}
          label="Enable OpenAI"
          name="OpenAI"
          onChange={(e) => handleChange(e, "OpenAI")}
        />
      </FormGroup>
      {values.DeploymentRegion === "london" && isOpenAI ? <FormHelperText>*You have selected the Deployment Region as London. Currently ATR Genwizard is not available in London region. Please select other deployment region in the previous step. Thank you!</FormHelperText> : ""}
    </div>
  );
}

export function EnableQuasarField(handleChange, values, isEnableQuasar) {
  return (
    <div>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={isEnableQuasar} />}
          label="Enable Quasar"
          name="EnableQuasar"
          onChange={(e) => handleChange(e, "EnableQuasar")}
        />
      </FormGroup>
    </div>
  );
}

// export function EnableMultisourceField(handleChange, values, isMultisource) {
//   return (
//     <div>
//       <FormGroup>
//         <FormControlLabel
//           control={<Checkbox checked={isMultisource}/>}
//           label="Enable Multisource"
//           name="EnableMultisource"
//           onChange={(e) => handleChange(e, "EnableMultisource")}
//         />
//       </FormGroup>
//     </div>
//   );
// }

export function AppIntegrationField(handleChange, values, appList, selectedApps) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-service-group">Applications To Provision</InputLabel>
      <StyledSelect
        multiple={true}
        value={values.AppsIntegration}
        onChange={(e) => handleChange(e, "AppsIntegration")}
        label="Applications To Provision"
        renderValue={(selected) => selected.join(', ')}
      >
        <MenuItem value="">
          <em>--Select Applications To Provision--</em>
        </MenuItem>
        {appList.map(item => (
          <MenuItem key={item} value={item}>
            <Checkbox checked={values.AppsIntegration.indexOf(item) > -1}
            />
            <ListItemText primary={item} />
          </MenuItem>
        ))}

      </StyledSelect>
    </StyledFormControl>
  );

}


export function DBTypeField(handleChange, values) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-database-type">Database Type</InputLabel>
      <StyledSelect
        value={values.DBType}
        onChange={(e) => handleChange(e, "DBType")}
        label="Database Type"
      >
        <MenuItem value="">
          <em>--Select Database Type--</em>
        </MenuItem>
        <MenuItem value="VM">VM DB</MenuItem>
        <MenuItem value="RDS">RDS Server</MenuItem>
      </StyledSelect>
    </StyledFormControl>
  );
}

export function ChatGPTFieldForATR(handleChange, values, isGDPR) {

  let GDPRversion = ["gpt-4, 0613", "gpt-4-32k, 0613", "gpt-35-turbo", "gpt-35-turbo-16k"]
  let nonGDPRversion = ["gpt-4o, 2024-05-13", "gpt-4-32k, 0613", "gpt-4, 0613", "gpt-35-turbo", "gpt-35-turbo-16k"]

  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-plan-and-scope-select">
        Chat GPT
      </InputLabel>
      <StyledSelect
        id="ChatGPT"
        margin="dense"
        variant="outlined"
        onChange={(e) => handleChange(e, "ChatGPT")}
        value={values.ChatGPT}
        label="Chat GPT"
        fullWidth
      >
        <MenuItem value="">
          <em>--Select gpt model--</em>
        </MenuItem>
        {
          isGDPR ?
            GDPRversion.map(item => (
              <MenuItem key={item} value={item}>{item}</MenuItem>
            ))
            :
            nonGDPRversion.map(item => (
              <MenuItem key={item} value={item}>{item}</MenuItem>
            ))
        }

      </StyledSelect>
      <FormHelperText>*availability of models is limited based on the gdpr selection.</FormHelperText>
    </StyledFormControl>
  );
}

export function ChatGPTFieldForQuasar(handleChange, values) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-plan-and-scope-select">
        Chat GPT
      </InputLabel>
      <StyledSelect
        id="ChatGPT"
        margin="dense"
        variant="outlined"
        onChange={(e) => handleChange(e, "ChatGPT")}
        value={values.ChatGPT}
        label="Chat GPT"
        fullWidth
      >
        <MenuItem value="">
          <em>--Select version--</em>
        </MenuItem>
        <MenuItem value="gpt-35-turbo-16k">3.5</MenuItem>
      </StyledSelect>
    </StyledFormControl>
  );
}

export function OpenAiRegionField(handleChange, values, isGDPR, gptVersion) {

  let gdpr_gpt_model_version = ["FRANCECENTRAL", "SWEDENCENTRAL", "SWITZERLANDNORTH"]
  let gpt_40 = ["EASTUS", "EASTUS2", "NORTHCENTRALUS", "SOUTHCENTRALUS", "WESTUS", "WESTUS3"]
  let gpt_4_32k = ["AUSTRALIAEAST", "CANADAEAST"]
  let gpt_4_0613 = ["AUSTRALIAEAST", "CANADAEAST"]
  let gpt_35 = ["AUSTRALIAEAST", "CANADAEAST", "EASTUS", "EASTUS2", "NORTHCENTRALUS"]

  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-plan-and-scope-select">
        OpenAI Hosting Regions
      </InputLabel>
      <StyledTooltip title={longTextOpenAiRegion} placement="left-start">
        <StyledSelect
          id="OpenAiRegion"
          margin="dense"
          variant="outlined"
          onChange={(e) => handleChange(e, "OpenAiRegion")}
          value={values.OpenAiRegion}
          label="OpenAI Hosting Regions"
          fullWidth
        >
          <MenuItem value="">
            <em>--Select openai hosting region--</em>
          </MenuItem>
          {
            isGDPR ?
              gdpr_gpt_model_version.map(item => (
                <MenuItem key={item} value={item}>{item}</MenuItem>
              ))
              :
              gptVersion === "gpt-4o, 2024-05-13" ?
                gpt_40.map(item => (
                  <MenuItem key={item} value={item}>{item}</MenuItem>
                ))
                : gptVersion === "gpt-4-32k, 0613" ?
                  gpt_4_32k.map(item => (
                    <MenuItem key={item} value={item}>{item}</MenuItem>
                  ))
                  : gptVersion === "gpt-4, 0613" ?
                    gpt_4_0613.map(item => (
                      <MenuItem key={item} value={item}>{item}</MenuItem>
                    ))
                    : gptVersion === "gpt-35-turbo" || gptVersion === "gpt-35-turbo-16k" ?
                      gpt_35.map(item => (
                        <MenuItem key={item} value={item}>{item}</MenuItem>
                      )) : ""
          }
        </StyledSelect>
      </StyledTooltip>
    </StyledFormControl>
  );
}

export function PlanAndScopeField(
  handleDevOpsAddon,
  PlanAndScope,
  isJiraSelectedByService,
  showWebservice
) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-plan-and-scope-select">
        Plan and Scope Add-On
      </InputLabel>
      <StyledSelect
        value={PlanAndScope}
        onChange={(e) => handleDevOpsAddon(e)}
        label="Plan and Scope Add-On"
      >
        <MenuItem value="">
          <em>--Select An Add-On--</em>
        </MenuItem>
        <MenuItem value="">None</MenuItem>
        {isJiraSelectedByService !== true && (
          <MenuItem value="Jira">Jira</MenuItem>
        )}
        {/* <MenuItem value="Rally">Rally</MenuItem>
        <MenuItem value="VersionOne">VersionOne</MenuItem>
        <MenuItem value="TFS">TFS</MenuItem>
        {showWebservice && <MenuItem value="Webservice">Webservice</MenuItem>} */}
      </StyledSelect>
    </StyledFormControl>
  );
}

export function SourceManagementField(
  handleDevOpsAddon,
  SourceManagement,
  showWebservice
) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-source-management-select">
        Source Management Add-On
      </InputLabel>
      <StyledSelect
        value={SourceManagement}
        onChange={(e) => handleDevOpsAddon(e)}
        label="Source Management Add-On"
      >
        <MenuItem value="">
          <em>--Select An Add-On--</em>
        </MenuItem>
        <MenuItem value="">None</MenuItem>
        {/* <MenuItem value="GitLab">GitLab</MenuItem>
        <MenuItem value="GitHub">GitHub</MenuItem> */}
        <MenuItem value="Newsource">Newsource</MenuItem>
        {/* <MenuItem value="BitBucket">BitBucket</MenuItem>
        <MenuItem value="TFSGit">TFSGit</MenuItem>
        {showWebservice && <MenuItem value="Webservice">Webservice</MenuItem>} */}
      </StyledSelect>
    </StyledFormControl>
  );
}

export function BuildAndDeploymentField(
  handleDevOpsAddon,
  BuildAndDeploymentManagement,
  showWebservice
) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-build-and-deployment-management-select">
        Build And Deployment Management Add-On
      </InputLabel>
      <StyledSelect
        onChange={(e) => handleDevOpsAddon(e)}
        label="Build And Deployment Management Add-On"
        value={BuildAndDeploymentManagement}
      >
        <MenuItem value="">
          <em>--Select An Add-On--</em>
        </MenuItem>
        <MenuItem value="">None</MenuItem>
        {/* <MenuItem value="Jenkins">Jenkins</MenuItem>
        <MenuItem value="Go">Go</MenuItem>
        <MenuItem value="Bamboo">Bamboo</MenuItem>
        <MenuItem value="TeamCity">TeamCity</MenuItem>
        <MenuItem value="JFrog">JFrog</MenuItem> */}
        <MenuItem value="AzurePipeline">AzurePipeline</MenuItem>
        {/* <MenuItem value="Concourse">Concourse</MenuItem>
        {showWebservice && <MenuItem value="Webservice">Webservice</MenuItem>} */}
      </StyledSelect>
    </StyledFormControl>
  );
}

export function QualityManagementField(
  handleDevOpsAddon,
  QualityManagement,
  showWebservice
) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-quality-management-select">
        Quality Management Add-On
      </InputLabel>
      <StyledSelect
        value={QualityManagement}
        onChange={(e) => handleDevOpsAddon(e)}
        label="Quality Management Add-On"
      >
        <MenuItem value="">
          <em>--Select An Add-On--</em>
        </MenuItem>
        <MenuItem value="">None</MenuItem>
        <MenuItem value="Sonarqube">Sonarqube</MenuItem>
        {/* <MenuItem value="ALM">ALM</MenuItem>
        <MenuItem value="Hiptest">Hiptest</MenuItem>
        {showWebservice && <MenuItem value="Webservice">Webservice</MenuItem>} */}
      </StyledSelect>
    </StyledFormControl>
  );
}

export function ServiceManagementField(
  handleDevOpsAddon,
  ServiceManagement,
  isJiraSelectedByPlan,
  showWebservice
) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-service-management-select">
        Service Management Add-On
      </InputLabel>
      <StyledSelect
        value={ServiceManagement}
        onChange={(e) => handleDevOpsAddon(e)}
        label="Service Management Add-On"
      >
        <MenuItem value="">
          <em>--Select An Add-On--</em>
        </MenuItem>
        <MenuItem value="">None</MenuItem>
        {/* {isJiraSelectedByPlan !== true && (
          <MenuItem value="Jira">Jira</MenuItem>
        )} */}
        <MenuItem value="ServiceNow">ServiceNow</MenuItem>
        {/* <MenuItem value="DevOps-ATR">DevOps-ATR</MenuItem>
        {showWebservice && <MenuItem value="Webservice">Webservice</MenuItem>} */}
      </StyledSelect>
    </StyledFormControl>
  );
}

export function DevSecOpsField(handleDevOpsAddon, DevSecOps) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-service-management-select">
        DevSecOps Add-On
      </InputLabel>
      <StyledSelect
        value={DevSecOps}
        onChange={(e) => handleDevOpsAddon(e)}
        label="DevSecOps Add-On"
      >
        <MenuItem value="">
          <em>--Select An Add-On--</em>
        </MenuItem>
        <MenuItem value="">None</MenuItem>
        <MenuItem value="NexusIQ">NexusIQ addon</MenuItem>
      </StyledSelect>
    </StyledFormControl>
  );
}

export function TicketFeedMethodFieldForManagedPlatform(handleChange, values) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-ticket-feed-method-select">
        Ticket Feed Method
      </InputLabel>
      <StyledSelect
        onChange={(e) => handleChange(e, "TicketFeed")}
        label="Ticket Feed Method"
      >
        <MenuItem value="">
          <em>--Select Ticket Feed Method--</em>
        </MenuItem>
        <MenuItem key="manualUpload" value="manualUpload">
          Manual Upload to myWizard AiOps
        </MenuItem>
        {/* <MenuItem key="sourceFromMywizard" value="sourceFromMywizard">Source From myWizard</MenuItem> */}
      </StyledSelect>
    </StyledFormControl>
  );
}

export function TicketFeedMethodField(handleChange, values) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-ticket-feed-method-select">
        Ticket Feed Method
      </InputLabel>
      <StyledSelect
        onChange={(e) => handleChange(e, "TicketFeed")}
        label="Ticket Feed Method"
      >
        <MenuItem value="">
          <em>--Select Ticket Feed Method--</em>
        </MenuItem>
        <MenuItem key="manualUpload" value="manualUpload">
          Manual Upload to myWizard AiOps
        </MenuItem>
        {/* <MenuItem key="sourceFromMywizard" value="sourceFromMywizard">Source From myWizard</MenuItem> */}
        <MenuItem key="availbleOverServiceNowAPI" value="availbleOverServiceNowAPI">
          Available Over ServiceNow API
        </MenuItem>
      </StyledSelect>
    </StyledFormControl>
  );
}

export function ServiceNowURlField(handleChange, values) {
  return (
    <StyledTextField
      label="Service Now URL"
      id="ServiceNowUrl"
      margin="dense"
      variant="outlined"
      value={values.ServiceNowUrl}
      onChange={(e) => handleChange(e, "ServiceNowUrl")}
    />
  );
}

export function ServiceNowUsernameField(handleChange, values) {
  return (
    <StyledTextField
      label="Service Now User Name"
      id="ServiceNowUserName"
      margin="dense"
      variant="outlined"
      value={values.ServiceNowUserName}
      onChange={(e) => handleChange(e, "ServiceNowUserName")}
    />
  );
}

export function ServiceNowPasswordField(
  handleChange,
  values,
  showPassword,
  handleShowPassword
) {
  return (
    <StyledTextField
      id="outlined-adornment-password"
      variant="outlined"
      type={showPassword ? "text" : "password"}
      label="Password"
      value={values.ServiceNowPassword}
      onChange={(e) => handleChange(e, "ServiceNowPassword")}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="Toggle password visibility"
              onClick={handleShowPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export function MonthlyTicketVolumeField(
  handleChange,
  values,
  isMonthlyTicketVolumeValid
) {
  return (
    <StyledTextField
      label="Monthly Ticket Volume"
      id="TicketMonthlyVolume"
      margin="dense"
      variant="outlined"
      //value={values.TicketMonthlyVolume}
      onChange={(e) => handleChange(e, "TicketMonthlyVolume")}
      helperText={
        values.TicketMonthlyVolume &&
        !isMonthlyTicketVolumeValid &&
        "Monthly Ticket Volume should be a number larger than 0."
      }
      error={!isMonthlyTicketVolumeValid}
      required
    />
  );
}

export function TermsAndConditionsField(
  handleChange,
  values,
  isAAAMTermsConditions
) {
  return (
    <RadioWrapper>
      <StyledFormLabel component="legend">
        <StyledArcher href="/AAAMTermsConditions" target="_blank">
          Terms And Conditions
        </StyledArcher>
      </StyledFormLabel>

      <StyledRadioGroup
        aria-label="AAAMTermsConditions"
        name="AAAMTermsConditions"
        value={values.AAAMTermsConditions}
        onChange={(e) => handleChange(e, "AAAMTermsConditions")}
        row
      >
        <FormControlLabel
          checked={isAAAMTermsConditions}
          value="yes"
          control={<Radio data-cy="AAAMTermsConditionsYes" color="primary" />}
          label="Yes"
        />
        <FormControlLabel
          checked={!isAAAMTermsConditions}
          value="no"
          control={<Radio data-cy="AAAMTermsConditionsNo" color="primary" />}
          label="No"
        />
      </StyledRadioGroup>
    </RadioWrapper>
  );
}

export function InstanceTypeField(handleChange, values) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-InstanceType">Instance Type</InputLabel>
      <StyledSelect
        value={values.InstanceType}
        onChange={(e) => handleChange(e, "InstanceType")}
        label="Instance Type"
      >
        <MenuItem value="">
          <em>--Select Instance Type--</em>
        </MenuItem>
        <MenuItem value="accentureManaged">Accenture Managed</MenuItem>
        <MenuItem value="clientManaged">Client Managed</MenuItem>
      </StyledSelect>
    </StyledFormControl>
  );
}

export function ProjectNameField(handleChange, values, isProjectNameValid) {
  return (
    <StyledTooltip title="This name will be used to identify and reference your project throughout the platform." placement="left-start">
      <StyledTextField
        label="Project Name"
        id="ProjectName"
        margin="dense"
        variant="outlined"
        value={values.ProjectName}
        onChange={(e) => handleChange(e, "ProjectName")}
        helperText={
          values.ProjectName &&
          !isProjectNameValid &&
          `Minimum 3 characters and Maximum 32 characters are allowed and you typed ${values.ProjectName.length}`
        }
        error={!isProjectNameValid}
      />
    </StyledTooltip>
  );
}

export function ChooseInstanceField(values) {
  return (
    <StyledTooltip
      title={longTextChooseTheExistingInstance}
      placement="left-start"
    >
      <StyledDefaultTextField
        label="Choose The Existing Instance"
        id="ChooseTheExistingInstance"
        margin="dense"
        variant="outlined"
        defaultValue={values.ChooseTheExistingInstance}
        InputProps={{
          readOnly: true,
        }}
      />
    </StyledTooltip>
  );
}

export function InstanceNameField(values) {
  return (
    <StyledTooltip title={longTextName} placement="left-start">
      <StyledDefaultTextField
        label="Instance Name"
        id="InstanceName"
        margin="dense"
        variant="outlined"
        defaultValue={values.InstanceName}
        InputProps={{
          readOnly: true,
        }}
      />
    </StyledTooltip>
  );
}

export function InstanceUrlField(values) {
  return (
    <StyledTooltip title={longTextUrl} placement="left-start">
      <StyledDefaultTextField
        label="Instance URL"
        id="InstanceUrl"
        margin="dense"
        variant="outlined"
        defaultValue={values.InstanceUrl}
        InputProps={{
          readOnly: true,
        }}
      />
    </StyledTooltip>
  );
}

export function AutomationAnywhereVersionField(handleChange, values) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-AAversion">
        Automation Anywhere Version
      </InputLabel>
      <StyledSelect
        value={values.AAversion}
        onChange={(e) => handleChange(e, "AAversion")}
        label="Automation Anywhere Version"
      >
        <MenuItem value="">
          <em>--Select Deployment Region--</em>
        </MenuItem>
        <MenuItem value="aa10">AA10</MenuItem>
        <MenuItem value="aa11">AA11</MenuItem>
        <MenuItem value="aa2019">AA2019</MenuItem>
      </StyledSelect>
    </StyledFormControl>
  );
}

export function DeploymentRegionField(values) {
  return (
    <StyledDefaultTextField
      label="Deployment Region"
      id="DeploymentRegion"
      margin="dense"
      variant="outlined"
      defaultValue={
        values.DeploymentRegion.length > 1 &&
        values.DeploymentRegion[0].toUpperCase() +
        values.DeploymentRegion.substring(1)
      }
      InputProps={{
        readOnly: true,
      }}
    />
  );
}

export function LicenseUsageField(handleChange, values) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-LicenseUsage">
        Bot Creator License Usage
      </InputLabel>
      <StyledSelect
        value={values.LicenseUsage}
        onChange={(e) => handleChange(e, "LicenseUsage")}
        label="Bot Creator License Usage"
      >
        <MenuItem value="">
          <em>--Select Bot Creator License Usage --</em>
        </MenuItem>
        <MenuItem value="Bot Creators used for System Integration (SI)">
          Bot Creators used for System Integration (SI)
        </MenuItem>
        <MenuItem value="Bot Creators used for Managed Service (MS)">
          Bot Creators used for Managed Service (MS)
        </MenuItem>
      </StyledSelect>
    </StyledFormControl>
  );
}

export function ControlRoomLicensesField(
  handleChange,
  values,
  isControlRoomLicensesValid
) {
  return (
    <StyledTooltip title={longTextLicenses} placement="left-start">
      <StyledTextField
        label="Control Room Licenses"
        id="NumOfControlRoomlicenses"
        margin="dense"
        variant="outlined"
        value={values.NumOfControlRoomlicenses}
        onChange={(e) => handleChange(e, "NumOfControlRoomlicenses")}
        helperText={
          values.NumOfControlRoomlicenses &&
          !isControlRoomLicensesValid &&
          "Number of Control Room Licenses should be a number larger than 0."
        }
        error={!isControlRoomLicensesValid}
      />
    </StyledTooltip>
  );
}

export function BotRunnerLicenseField(
  handleChange,
  values,
  isBotRunnerLicensesValid
) {
  return (
    <StyledTooltip title={longTextLicenses} placement="left-start">
      <StyledTextField
        label="Bot Runner Licenses"
        id="NumOfRunnerlicenses"
        margin="dense"
        variant="outlined"
        value={values.NumOfRunnerlicenses}
        onChange={(e) => handleChange(e, "NumOfRunnerlicenses")}
        helperText={
          values.NumOfRunnerlicenses &&
          !isBotRunnerLicensesValid &&
          "Number of Bot Runner Licenses should be a number larger than 0."
        }
        error={!isBotRunnerLicensesValid}
      />
    </StyledTooltip>
  );
}

export function BotCreatorLicenseField(
  handleChange,
  values,
  isBotCreatorLicensesValid
) {
  return (
    <StyledTooltip title={longTextLicenses} placement="left-start">
      <StyledTextField
        label="Bot Creator Licenses"
        id="NumOfDeveloperlicenses"
        margin="dense"
        variant="outlined"
        value={values.NumOfDeveloperlicenses}
        onChange={(e) => handleChange(e, "NumOfDeveloperlicenses")}
        helperText={
          values.NumOfDeveloperlicenses &&
          !isBotCreatorLicensesValid &&
          "Number of Bot Creator Licenses should be a number larger than 0."
        }
        error={!isBotCreatorLicensesValid}
      />
    </StyledTooltip>
  );
}

export function RequestNotesField(handleChange, values, notesValidations) {
  return (
    <StyledTooltip title={longTextNotes} placement="left-start">
      <StyledTextField
        label="Request Notes"
        id="RequestNotes"
        margin="dense"
        variant="outlined"
        multiline
        rows="4"
        error={
          notesValidations.emptyString || (notesValidations.maxLength && true)
        }
        helperText={
          notesValidations.emptyString
            ? "Please enter at least 1 character"
            : notesValidations.maxLength
              ? `Maximum characters allowed 247 and you typed ${values.RequestNotes.length}`
              : `${values.RequestNotes.length}/247`
        }
        value={values.RequestNotes}
        onChange={(e) => handleChange(e, "RequestNotes")}
      />
    </StyledTooltip>
  );
}

export function EndToEndUIdPlatformField(values, handleChange, endToEndUid) {
  return (
    <StyledDefaultTextField
      key="EndToEndUId"
      label="End To End ID*"
      // value={values.EndToEndUId}
      value={endToEndUid}
      id="EndToEndUId"
      margin="dense"
      variant="outlined"
      defaultValue={endToEndUid}
      InputProps={{
        readOnly: true,
      }}
      onChange={(e) => handleChange(e, "EndToEndUId")}
    // error={selectedClientname === "" || sapContractIdValue === "" ? "" : (error == false ? true : false)}
    // helperText={selectedClientname === "" || sapContractIdValue === "" ? "" : (error ? true :
    //   "Please check with your client & enter a valid End To End UID")
    // }
    // onChange={(e) => handleChangeEndToEndUid(e, "EndToEndUId")}
    // disabled={selectedClientname === "" || sapContractIdValue === "" ? "true" : (enableEND == false ? true : false)}
    />
  );
}

export function EndToEndUIdPlatformField1(values, handleChangeEndToEndUid, error, endToEndUid) {
  return (
    <StyledTextField
      key="EndToEndUId"
      label="myWizard End To End UID*"
      value={values.EndToEndUId}
      // value = {endToEndUid}
      id="EndToEndUId1"
      margin="dense"
      variant="outlined"
      error={error == false ? true : false}
      helperText={error ? true :
        "Please check with your client & enter a valid End To End UID"
      }
      onChange={(e) => handleChangeEndToEndUid(e, "EndToEndUId")}
    />
  );
}

export function DeployRegionPlatformField(values, handleChange) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="deploy-region-select">Deploy Region</InputLabel>
      <StyledSelect
        value={values.DeployRegion}
        onChange={(e) => handleChange(e, "DeployRegion")}
        label="Deploy Region"
      >
        <MenuItem value="">
          <em>--Select Deploy Region--</em>
        </MenuItem>
        <MenuItem value="NA">North America</MenuItem>
        <MenuItem value="EU">Europe</MenuItem>
      </StyledSelect>
    </StyledFormControl>
  );
}

export function ClientNamePlatformFieldOthers(values, handleChange) {
  return (
    <StyledTextField
      label="Client Name"
      id="ClientName"
      margin="dense"
      variant="outlined"
      value={values.ClientName}
      onChange={(e) => handleChange(e, "ClientName")}
    />
  );
}

export function SAPContractIDPlatformFieldOthers(values, handleChange) {
  return (
    <StyledTooltip title={longTextDNS} placement="left-start">
      <StyledTextField
        label="SAP Contract ID"
        id="SAPContractID"
        margin="dense"
        variant="outlined"
        value={values.SAPContractID}
        onChange={(e) => handleChange(e, "SAPContractID")}
      />
    </StyledTooltip>
  );
}

export function SAPContractIDForLicense(handleChangeSAPContractId, values) {
  return (
    <StyledTooltip title="Please input either your Project or Client Name" placement="left-start">
      <StyledTextField
        label="SAP Contract ID"
        id="SAPContractID"
        margin="dense"
        variant="outlined"
        value={values.SAPContractID}
        onChange={(e) => handleChangeSAPContractId(e, "SAPContractID")}
        InputProps={{
          maxLength: 30,
        }}
      />
    </StyledTooltip>
  );
}


export function ClientNamePlatformField(values, handleChange, clientName) {
  return (
    <StyledDefaultTextField
      label="Client Name"
      id="ClientName"
      margin="dense"
      variant="outlined"
      value={clientName}
      onChange={(e) => handleChange(e, "ClientName")}
      defaultValue={clientName}
      InputProps={{
        readOnly: true,
      }}
    />
  );
  // return (
  //   <Autocomplete
  //     id="ClientName"
  //     options={clientNameDetailsData}
  //     // value={values.ClientName}
  //     disableClearable
  //     getOptionLabel={option => option.MasterClient}
  //     onChange={(e, value) => handleChangeClientName(e, value, "ClientName")}
  //     renderInput={params => (
  //       <StyledTextField {...params}
  //         key="ClientName"
  //         label="Client Name*"
  //         variant="outlined"
  //         margin="dense"
  //       // value={values.ClientName}
  //       //onChange={(e) => handleChange(e, "ClientName")}
  //       />
  //     )}
  //   />
  // )
}

export function SAPContractIDPlatformField(values, handleChange, error, isExternalClientLoading, sapContractCodeLength, config) {
  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  return (
    <StyledTooltip title={longTextDNS} placement="left-start">
      <StyledTextField
        type="number"
        label="SAP Contract ID"
        id="SAPContractID"
        value={values.SAPContractID}
        margin="dense"
        variant="outlined"
        inputProps={{
          maxLength: 10,
        }}
        onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
        // value={values.SAPContractID}
        onChange={(e) => handleChange(e, "SAPContractID")}
        InputProps={isExternalClientLoading ? {
          endAdornment: (
            <InputAdornment position="end">
              <CircularProgress size={24} />
            </InputAdornment>
          ),
        } : !error && sapContractCodeLength == 10 ? {
          endAdornment: (
            <InputAdornment position="end">
              <CheckCircle size={24} style={{ color: "#4CAF50" }} id="verifiedIcon" />
            </InputAdornment>
          ),
        } : ""
        }
        error={sapContractCodeLength !== 10 && sapContractCodeLength > 0 ? true : error ? true : false}
        helperText={
          sapContractCodeLength !== 10 && sapContractCodeLength > 0
            ? "Please enter 10 digit valid SAP Contract ID"
            : error == false ? "" :
              "Please check with your client & enter a valid SAP Contract ID"
        }
      />
    </StyledTooltip>
  );
}

export function SAPContractIDPlatformField1(values, handleChangeSAPContractId, error, sapContractId) {
  return (
    <StyledTooltip title={longTextDNS} placement="left-start">
      <StyledTextField
        label="SAP Contract ID*"
        id="SAPContractID1"
        margin="dense"
        variant="outlined"
        value={values.SAPContractID}
        // value={sapContractId}
        error={error == false ? true : false}
        helperText={error ? true :
          "Please check with your client & enter a valid SAP Contract ID"
        }
        onChange={(e) => handleChangeSAPContractId(e, "SAPContractID", error)}
      />
    </StyledTooltip>
  );
}

// export function PaymentRateField(handleChange, values, isPaymentRate) {
//   return (
//       <RadioWrapper>
//         <StyledFormLabel component="legend">Payment Rate</StyledFormLabel>
//         <StyledRadioGroup
//           aria-label="payment-rate"
//           name="PaymentRate"
//           value={values.PaymentRate}
//           onChange={(e) => handleChange(e, "PaymentRate")}
//           row
//         >
//           <FormControlLabel
//             checked={!isPaymentRate}
//             value="dedicated"
//             control={<Radio data-cy="PaymentRateDedicated" color="primary" />}
//             margin="dense"
//             label="Dedicated"
//           />
//           <FormControlLabel
//             checked={isPaymentRate}
//             value="payasyougo"
//             control={<Radio data-cy="PaymentRatePay" color="primary" />}
//             margin="dense"
//             label="Pay as you go"
//           />
//         </StyledRadioGroup>
//       </RadioWrapper>
//   );
// }

export function DomainContextRootPlatformField(
  values,
  handleChangeRoot,
  isRootExist,
  rootExistedHint,
  isLoading
) {
  return (
    <StyledTooltip title={longTextRoot} placement="left-start">
      <StyledTextField
        label="Domain Context Root"
        id="DomainContextRoot"
        margin="dense"
        variant="outlined"
        value={values.DomainContextRoot}
        onChange={(e) => handleChangeRoot(e, "DomainContextRoot")}
        InputProps={
          isLoading ? {
            endAdornment: (
              <InputAdornment position="end">
                <CircularProgress size={24} />
              </InputAdornment>
            ),
          } : !isRootExist && values.DomainContextRoot.length !== 0 ? {
            endAdornment: (
              <InputAdornment position="end">
                <CheckCircle size={24} style={{ color: "#4CAF50" }} id="verifiedIcon" />
              </InputAdornment>
            ),
          } : ""
        }
        helperText={
          values.DomainContextRoot && isRootExist && `${rootExistedHint}`
        }
        error={values.DomainContextRoot && isRootExist && true}
      />
    </StyledTooltip>
  );
}

export function DomainContextRootPlatformFieldClient(
  values,
  handleChangeRootSuggestions,
  isRootExist,
  rootExistedHint,
  rootWrongFormatHint,
  clientname,
  domainContextRoot,
  isLoading
) {
  const suggestions = [];
  const re = /^[a-z0-9]*$/;
  if (clientname !== "") {
    if (clientname[0]) {
      const strLower = clientname[0].toLowerCase();
      const clientvalue = strLower.replace(/\W/g, '');
      suggestions.push(clientvalue);
    }
  }
  return (
    <Autocomplete
      freeSolo
      id="DomainContextRoot"
      value={values.DomainContextRoot}
      options={suggestions}
      onInputChange={(e, value) => handleChangeRootSuggestions(e, value, "DomainContextRoot")}
      renderInput={(params) => <StyledTooltip title={longTextRoot} placement="left-start">
        <StyledTextField {...params}
          label="Domain Context Root"
          margin="dense"
          variant="outlined"
          value={values.DomainContextRoot}
          InputProps={
            isLoading ? {
              endAdornment: (
                <InputAdornment position="end">
                  <CircularProgress size={24} />
                </InputAdornment>
              ),
            } : !isRootExist && values.DomainContextRoot.length !== 0 ? {
              endAdornment: (
                <InputAdornment position="end">
                  <CheckCircle size={24} style={{ color: "#4CAF50" }} id="verifiedIcon" />
                </InputAdornment>
              ),
            } : ""
          }
          helperText={
            re.test(domainContextRoot) || domainContextRoot === "[__]" ? values.DomainContextRoot && isRootExist && `${rootExistedHint}` : `${rootWrongFormatHint}`
          }
          error={re.test(domainContextRoot) || domainContextRoot === "[__]" ? values.DomainContextRoot && isRootExist && true : true}
        />
      </StyledTooltip>
      }
    />

  );
}


export function GDPRPlatformField(values, handleChange, isGDPR, classes) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <RadioWrapper>
        <StyledFormLabel component="legend">GDPR</StyledFormLabel>
        <StyledRadioGroup
          aria-label="GDPR"
          name="GDPR"
          value={values.GDPR}
          onChange={(e) => handleChange(e, "GDPR")}
          row
        >
          <FormControlLabel
            onClick={handleClickOpen}
            checked={isGDPR}
            value="yes"
            control={<Radio data-cy="GDPRYES" color="primary" />}
            label="Yes"
          />
          <FormControlLabel
            checked={!isGDPR}
            value="no"
            control={<Radio data-cy="GDPRNO" color="primary" />}
            label="No"
          />
        </StyledRadioGroup>
      </RadioWrapper>
      <CommonDialog
        open={open}
        onClose={handleClose}
        title={"GDPR Disclaimer Alert"}
        dialogType={"fui"}
        dialogContent={
          <DialogContent>
            <DialogContentText id="alert-dialog-descreption">
              <AlertContent>
                <Subtitle3 variant="body1" gutterBottom>
                  {alertPartOne}
                </Subtitle3>
                <Subtitle3 variant="body1" gutterBottom>
                  {alertPartTwo}
                </Subtitle3>

                <Subtitle5 variant="body1" gutterBottom>
                  {alertPartThree}
                </Subtitle5>
                <Subtitle5 variant="body1" gutterBottom>
                  {alertPartFour}
                </Subtitle5>
                <Subtitle3 variant="body1" gutterBottom>
                  {alertPartFive}
                </Subtitle3>
              </AlertContent>
            </DialogContentText>
          </DialogContent>
        }
        dialogActions={
          <DialogActions>
            <PrimaryButton
              data-cy="dialogOkButton"
              variant="outlined"
              onClick={handleClose}
              label={"Okay"}
              className={classes.FuiPrimaryButton}
            />
          </DialogActions>
        }
      ></CommonDialog>
    </div>
  );
}

export function GDPREventOpsPlatformField(values, handleChange, isGDPR, classes, config) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    !config ? <div>
      <RadioWrapper>
        <StyledFormLabel component="legend">GDPR</StyledFormLabel>
        <StyledRadioGroup
          aria-label="GDPR"
          name="GDPR"
          value={values.GDPR}
          onChange={(e) => handleChange(e, "GDPR")}
          row
        >
          <FormControlLabel
            onClick={handleClickOpen}
            checked={isGDPR}
            value="yes"
            control={<Radio data-cy="GDPRYES" color="primary" />}
            label="Yes"
          />
          <FormControlLabel
            checked={!isGDPR}
            value="no"
            control={<Radio data-cy="GDPRNO" color="primary" />}
            label="No"
          />
        </StyledRadioGroup>
      </RadioWrapper>
      <CommonDialog
        open={open}
        onClose={handleClose}
        title={"GDPR Disclaimer Alert"}
        dialogType={"fui"}
        dialogContent={
          <DialogContent>
            <DialogContentText id="alert-dialog-descreption">
              <AlertContent>
                <Subtitle3 variant="body1" gutterBottom>
                  {alertPartOne}
                </Subtitle3>
                <Subtitle3 variant="body1" gutterBottom>
                  {alertPartTwo}
                </Subtitle3>

                <Subtitle5 variant="body1" gutterBottom>
                  {alertPartThree}
                </Subtitle5>
                <Subtitle5 variant="body1" gutterBottom>
                  {alertPartFour}
                </Subtitle5>
                <Subtitle3 variant="body1" gutterBottom>
                  {alertPartFive}
                </Subtitle3>
              </AlertContent>
            </DialogContentText>
          </DialogContent>
        }
        dialogActions={
          <DialogActions>
            <PrimaryButton
              data-cy="dialogOkButton"
              variant="outlined"
              onClick={handleClose}
              label={"Okay"}
              className={classes.FuiPrimaryButton}
            />
          </DialogActions>
        }
      ></CommonDialog>
    </div>
      : ""
  );
}

export function InstanceTypePlatformField(values, handleChange, isDedicated, classes) {
  return (
    <div>
      <RadioWrapper>
        <StyledFormLabel component="legend">Instance Type</StyledFormLabel>
        <StyledRadioGroup
          aria-label="InstanceType"
          name="InstanceType"
          value={values.InstanceType}
          onChange={(e) => handleChange(e, "InstanceType")}
          row
        >
          <FormControlLabel
            checked={isDedicated}
            value="Dedicated"
            control={<Radio data-cy="DedicatedATR" color="primary" />}
            label="Dedicated ATR"
          />
          <FormControlLabel
            checked={!isDedicated}
            value="Container"
            control={<Radio data-cy="ContainerATR" color="primary" />}
            label="ContainerATR"
          />
        </StyledRadioGroup>
      </RadioWrapper>
    </div>
  );
}

export function QppInstanceTypePlatformField(values, handleChange, isDedicated, classes) {
  return (
    <div>
      <RadioWrapper>
        <StyledFormLabel component="legend">Instance Type</StyledFormLabel>
        <StyledRadioGroup
          aria-label="InstanceType"
          name="InstanceType"
          value={values.InstanceType}
          onChange={(e) => handleChange(e, "InstanceType")}
          row
        >
          <FormControlLabel
            checked={isDedicated}
            value="Dedicated"
            control={<Radio data-cy="DedicatedQ++" color="primary" />}
            label="Dedicated Q++"
          />
          <FormControlLabel
            checked={!isDedicated}
            value="Container"
            control={<Radio data-cy="ContainerQ++" color="primary" />}
            label="Container Q++"
          />
        </StyledRadioGroup>
      </RadioWrapper>
    </div>
  );
}


export function HighAvailabilityPlaformField(values, handleChange, isHighAv) {
  return (
    <div>
      <RadioWrapper>
        <StyledFormLabel component="legend">High Availability</StyledFormLabel>
        <StyledRadioGroup
          aria-label="HighAvailability"
          name="HighAvailability"
          value={values.HighAvailability}
          onChange={(e) => handleChange(e, "HighAvailability")}
          row
        >
          <FormControlLabel
            value="yes"
            control={<Radio color="primary" />}
            label="Yes"
          />
          <FormControlLabel
            checked={!isHighAv}
            value="no"
            control={<Radio color="primary" />}
            label="No"
          />
        </StyledRadioGroup>
      </RadioWrapper>
    </div>
  );
}

export function DeploymentRegionPlatformField(
  values,
  handleChange,
  isGDPR,
  isStack
) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-Region">Deployment Region</InputLabel>
      <StyledSelect
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={values.DeploymentRegion}
        onChange={(e) => handleChange(e, "DeploymentRegion")}
        label="Deployment Region"
      >
        <MenuItem value="">
          <em>--Select Deployment Region--</em>
        </MenuItem>
        {!isGDPR && !isStack && (
          <MenuItem value="singapore">Singapore</MenuItem>
        )}
        {!isGDPR && !isStack && <MenuItem value="sydney">Sydney</MenuItem>}
        {!isGDPR && !isStack && (
          <MenuItem value="frankfurt">Frankfurt</MenuItem>
        )}
        {!isGDPR && <MenuItem value="virginia">Virginia</MenuItem>}
        {!isGDPR && !isStack && <MenuItem value="tokyo">Tokyo</MenuItem>}
        {!isGDPR && !isStack && <MenuItem value="london">London</MenuItem>}
        {isGDPR && <MenuItem value="ireland">Ireland</MenuItem>}
      </StyledSelect>
    </StyledFormControl>
  );
}

export function Capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}


export function DeploymentRegionATRPlatformField(
  values,
  handleChange,
  isGDPR,
  isDedicated,
  isOpenAI,
  instanceType
) {

  let atr_caas_reg = ["sydney", "virginia", "tokyo"]
  let atr_ded_reg = ["sydney", "virginia", "frankfurt", "singapore", "tokyo", "london"]
  let atr_ded_reg_isopenai = ["sydney", "virginia", "frankfurt", "singapore", "tokyo"]

  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-Region">Deployment Region</InputLabel>
      <StyledSelect
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={values.DeploymentRegion}
        onChange={(e) => handleChange(e, "DeploymentRegion")}
        label="Deployment Region"
      >
        <MenuItem value="">
          <em>--Select Deployment Region--</em>
        </MenuItem>
        {
          (() => {
            if (!isGDPR)
              if (instanceType === "Dedicated" && isOpenAI)
                return atr_ded_reg_isopenai.map(item => (
                  <MenuItem key={item} value={item}>{Capitalize(item)}</MenuItem>
                ))
              else if (instanceType === "Dedicated" && !isOpenAI)
                return atr_ded_reg.map(item => (
                  <MenuItem key={item} value={item}>{Capitalize(item)}</MenuItem>
                ))
              else return atr_caas_reg.map(item => (
                <MenuItem key={item} value={item}>{Capitalize(item)}</MenuItem>
              ))
            else
              return <MenuItem value="ireland">Ireland</MenuItem>
          })()
        }
        {/* {!isGDPR ?
          instanceType === "Dedicated" ? atr_ded_reg.map(item => (
            <MenuItem key={item} value={item}>{Capitalize(item)}</MenuItem>
          )) : atr_caas_reg.map(item => (
            <MenuItem key={item} value={item}>{Capitalize(item)}</MenuItem>
          )) : <MenuItem value="ireland">Ireland</MenuItem>
        } */}

        {/* {!isGDPR && isDedicated && (
          <MenuItem value="singapore">Singapore</MenuItem>
        )}
        {!isGDPR && <MenuItem value="sydney">Sydney</MenuItem>}
        {!isGDPR && isDedicated && (
          <MenuItem value="frankfurt">Frankfurt</MenuItem>
        )}
        {!isGDPR && <MenuItem value="virginia">Virginia</MenuItem>}
        {!isGDPR && <MenuItem value="tokyo">Tokyo</MenuItem>}
        {!isGDPR && isDedicated && <MenuItem value="london">London</MenuItem>}
        {isGDPR && <MenuItem value="ireland">Ireland</MenuItem>} */}
      </StyledSelect>
      {/* {values.DeploymentRegion === "london" && isOpenAI ? <FormHelperText>*Currently Quasar is not available in London region</FormHelperText> : ""} */}
    </StyledFormControl>
  );
}


export function DeploymentRegionQuasarPPPlatformField(
  values,
  handleChange,
  isGDPR,
  isDedicated,
  isOpenAI,
  instanceType
) {

  let atr_caas_reg = ["sydney", "virginia", "tokyo"]
  // let atr_ded_reg = ["sydney", "virginia", "frankfurt", "singapore", "tokyo", "london"]
  let atr_ded_reg = ["sydney", "virginia", "frankfurt", "singapore", "tokyo"]

  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-Region">Deployment Region</InputLabel>
      <StyledSelect
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={values.DeploymentRegion}
        onChange={(e) => handleChange(e, "DeploymentRegion")}
        label="Deployment Region"
      >
        <MenuItem value="">
          <em>--Select Deployment Region--</em>
        </MenuItem>
        {!isGDPR ?
          instanceType === "Dedicated" ? atr_ded_reg.map(item => (
            <MenuItem key={item} value={item}>{Capitalize(item)}</MenuItem>
          )) : atr_caas_reg.map(item => (
            <MenuItem key={item} value={item}>{Capitalize(item)}</MenuItem>
          )) : <MenuItem value="ireland">Ireland</MenuItem>
        }

        {/* {!isGDPR && isDedicated && (
          <MenuItem value="singapore">Singapore</MenuItem>
        )}
        {!isGDPR && <MenuItem value="sydney">Sydney</MenuItem>}
        {!isGDPR && isDedicated && (
          <MenuItem value="frankfurt">Frankfurt</MenuItem>
        )}
        {!isGDPR && <MenuItem value="virginia">Virginia</MenuItem>}
        {!isGDPR && <MenuItem value="tokyo">Tokyo</MenuItem>}
        {!isGDPR && isDedicated && <MenuItem value="london">London</MenuItem>}
        {isGDPR && <MenuItem value="ireland">Ireland</MenuItem>} */}
      </StyledSelect>
    </StyledFormControl>
  );
}


export function DeploymentRegionEventOpsPlatformField(
  values,
  handleChange,
  isGDPR,
  isDedicated,
  config
) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-Region">Deployment Region</InputLabel>
      <StyledSelect
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={values.DeploymentRegion}
        onChange={(e) => handleChange(e, "DeploymentRegion")}
        label="Deployment Region"
      >
        <MenuItem value="">
          <em>--Select Deployment Region--</em>
        </MenuItem>
        {!isGDPR && !config && (
          <MenuItem value="singapore">Singapore</MenuItem>
        )}
        {config && <MenuItem value="ohio">Ohio</MenuItem>}
        {!isGDPR && !config && <MenuItem value="sydney">Sydney</MenuItem>}
        {isGDPR && !config && (
          <MenuItem value="frankfurt">Frankfurt</MenuItem>
        )}

        {!isGDPR && !config && <MenuItem value="virginia">Virginia</MenuItem>}
        {/* {!isGDPR && isDedicated && <MenuItem value="tokyo">Tokyo</MenuItem>}
        {!isGDPR && isDedicated && <MenuItem value="london">London</MenuItem>}
        {isGDPR && isDedicated && <MenuItem value="ireland">Ireland</MenuItem>} */}
      </StyledSelect>
    </StyledFormControl>
  );
}

export function DeploymentRegionGenWizardPlatformField(
  values,
  handleChange,
  isGDPR,
) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-Region">Deployment Region</InputLabel>
      <StyledSelect
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={values.DeploymentRegion}
        onChange={(e) => handleChange(e, "DeploymentRegion")}
        label="Deployment Region"
      >
        <MenuItem value="">
          <em>--Select Deployment Region--</em>
        </MenuItem>

        {isGDPR && (
          <MenuItem value="frankfurt">Frankfurt</MenuItem>
        )}
        {!isGDPR && <MenuItem value="virginia">Virginia</MenuItem>}

      </StyledSelect>
    </StyledFormControl>
  );
}

export function DeploymentRegionPlatformMccField(values, handleChange) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-Region">Deployment Region</InputLabel>
      <StyledSelect
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={values.DeploymentRegion}
        onChange={(e) => handleChange(e, "DeploymentRegion")}
        label="Deployment Region"
      >
        <MenuItem value="">
          <em>--Select Deployment Region--</em>
        </MenuItem>
        {<MenuItem value="virginia">Virginia</MenuItem>}
      </StyledSelect>
    </StyledFormControl>
  );
}

export function GeographicAreaPlatformField(values, handleChange) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-geographic-area">
        Geographic Area
      </InputLabel>
      <StyledSelect
        value={values.GeographicArea}
        onChange={(e) => handleChange(e, "GeographicArea")}
        label="Geographic Area"
      >
        <MenuItem value="">
          <em>--Select Geographic Area--</em>
        </MenuItem>
        <MenuItem value="APAC">APAC</MenuItem>
        <MenuItem value="NA">NA</MenuItem>
        <MenuItem value="EALA">EALA</MenuItem>
      </StyledSelect>
    </StyledFormControl>
  );
}

export function BenefitsExpectedPlatformField(
  values,
  handleChange,
  isBenefitsExpectedValid
) {
  return (
    <StyledTextField
      label="Benefits Expected ($USD)"
      id="BenefitsExpected"
      margin="dense"
      variant="outlined"
      value={values.BenefitsExpected}
      helperText={
        values.BenefitsExpected &&
        !isBenefitsExpectedValid &&
        "Please input valid Benefits Expected."
      }
      error={!isBenefitsExpectedValid}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      onChange={(e) => handleChange(e, "BenefitsExpected")}
    />
  );
}

export function EnvironmentEventOpsPlatformField(values, handleChange, config) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-Environment">Environment</InputLabel>
      <StyledSelect
        value={values.Environment}
        onChange={(e) => handleChange(e, "Environment")}
        label="Environment"
      >
        <MenuItem value="">
          <em>--Select Environment--</em>
        </MenuItem>
        {!config && <MenuItem value="Production">Production</MenuItem>}
        <MenuItem value="Dev">Dev</MenuItem>
      </StyledSelect>
    </StyledFormControl>
  );
}

export function EnvironmentPlatformField(values, handleChange) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-Environment">Environment</InputLabel>
      <StyledSelect
        value={values.Environment}
        onChange={(e) => handleChange(e, "Environment")}
        label="Environment"
      >
        <MenuItem value="">
          <em>--Select Environment--</em>
        </MenuItem>
        <MenuItem value="Production">Production</MenuItem>
        <MenuItem value="Dev">Development</MenuItem>
        <MenuItem value="Sales">Sales</MenuItem>
        <MenuItem value="Test">Test</MenuItem>
      </StyledSelect>
    </StyledFormControl>
  );
}

export function DailyDataVolumePlatformField(
  values,
  handleChange,
  isDailyDataVolumeValid
) {
  return (
    <StyledTextField
      label="Daily Data Volume in GB"
      id="DailyDataVolume"
      margin="dense"
      variant="outlined"
      value={values.DailyDataVolume}
      onChange={(e) => handleChange(e, "DailyDataVolume")}
      helperText={
        values.DailyDataVolume &&
        !isDailyDataVolumeValid &&
        "Daily Data Volume should be a number larger than 0 and less than 201."
      }
      error={!isDailyDataVolumeValid}
    />
  );
}

export function DailyDataVolumeSplunkLicensePlatformField(
  handleChange, values, dataVolumes, selectedDataVolumeSizes, totalSelectedDailyDataVolume
) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-service-group">Daily Data Volume in GB</InputLabel>
      <StyledSelect
        multiple={true}
        id="DailyDataVolume"
        value={values.SplitDataVolume}
        onChange={(e) => handleChange(e, "DailyDataVolume")}
        label="Total Daily Data Volume in GB"
        renderValue={(selected) => totalSelectedDailyDataVolume + "GB (" + selected.join(' + ') + ")"}
      // helperText={
      //   values.DailyDataVolume &&
      //   !isDailyDataVolumeValid &&
      //   "Daily Data Volume should be a number larger than 0 and less than 201."
      // }
      // error={!isDailyDataVolumeValid}
      >
        <MenuItem value="">
          <em>--Select Daily Data Volume--</em>
        </MenuItem>
        {dataVolumes.map(item => (
          <MenuItem key={item} value={item}>
            {/* {item} */}
            <Checkbox checked={values.SplitDataVolume.indexOf(item) > -1} />
            <ListItemText primary={item} />
          </MenuItem>
        ))}

      </StyledSelect>
    </StyledFormControl>
  );
}

export function ConcurrentUsersPlatformField(
  values,
  handleChange,
  isConcurrentUsersValid
) {
  return (
    <StyledTextField
      label="Concurrent Users"
      id="ConcurrentUsers"
      margin="dense"
      variant="outlined"
      value={values.ConcurrentUsers}
      onChange={(e) => handleChange(e, "ConcurrentUsers")}
      helperText={
        values.ConcurrentUsers &&
        !isConcurrentUsersValid &&
        "Concurrent Users should be a number larger than 0 and less than 9."
      }
      error={!isConcurrentUsersValid}
    />
  );
}

export function RequestNotesPlatformField(
  values,
  handleChange,
  notesValidations
) {
  return (
    <StyledTooltip title={longTextNotes} placement="left-start">
      <StyledTextField
        label="Request Notes"
        id="RequestNotes"
        margin="dense"
        variant="outlined"
        multiline
        rows="3"
        inputProps={{
          maxLength: 200,
        }}
        error={
          notesValidations.emptyString || (notesValidations.maxLength && true)
        }
        helperText={
          notesValidations.emptyString
            ? "Please enter at least 1 character"
            : notesValidations.maxLength
              ? `Maximum characters allowed 200 and you typed ${values.RequestNotes.length}`
              : `${values.RequestNotes.length}/200`
        }
        value={values.RequestNotes}
        onChange={(e) => handleChange(e, "RequestNotes")}
      />
    </StyledTooltip>
  );
}

export function TierPlatformField(values, handleChange) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-Tier">Tier</InputLabel>
      <StyledSelect
        value={values.Tier}
        onChange={(e) => handleChange(e, "Tier")}
        label="Tier"
      >
        <MenuItem value="">
          <em>--Select Tier--</em>
        </MenuItem>
        <MenuItem value="bronze">Bronze</MenuItem>
        <MenuItem value="silver">Silver</MenuItem>
        <MenuItem value="gold">Gold</MenuItem>
        <MenuItem value="platinum">Platinum</MenuItem>
        <MenuItem value="diamond">Diamond</MenuItem>
      </StyledSelect>
    </StyledFormControl>
  );
}

export function SplunkClusterTierPlatformField(values, handleChange, isStack) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-Tier">Tier</InputLabel>
      <StyledSelect
        //value={!isStack ? values.Tier : "gold"}
        value={values.Tier}
        onChange={(e) => handleChange(e, "Tier")}
        label="Tier"
      >
        <MenuItem value="">
          <em>--Select Tier--</em>
        </MenuItem>
        {/*!isStack && <MenuItem value="bronze">Bronzee</MenuItem>}
        {isStack && <MenuItem value="gold">Gold</MenuItem>}
        {!isStack && <MenuItem value="platinum">Platinum</MenuItem> */}
        <MenuItem value="bronze">Bronze</MenuItem>
        <MenuItem value="silver">Silver</MenuItem>
        <MenuItem value="gold">Gold</MenuItem>
        <MenuItem value="platinum">Platinum</MenuItem>
        <MenuItem value="diamond">Diamond</MenuItem>
      </StyledSelect>
    </StyledFormControl>
  );
}

export function AtrTierPlatformField(values, handleChange) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-Tier">Tier</InputLabel>
      <StyledSelect
        value={values.Tier}
        onChange={(e) => handleChange(e, "Tier")}
        label="Tier"
      >
        <MenuItem value="">
          <em>--Select Tier--</em>
        </MenuItem>
        <MenuItem value="gold">Gold</MenuItem>
        <MenuItem value="platinum">Platinum</MenuItem>
        <MenuItem value="diamond">Diamond</MenuItem>
      </StyledSelect>
    </StyledFormControl>
  );
}

export function StormSeerTierPlatformField(values, handleChange) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-Tier">Tier</InputLabel>
      <StyledSelect
        value={values.Tier}
        onChange={(e) => handleChange(e, "Tier")}
        label="Tier"
      >
        <MenuItem value="">
          <em>--Select Tier--</em>
        </MenuItem>
        <MenuItem value="gold">Gold</MenuItem>
        <MenuItem value="platinum">Platinum</MenuItem>
        <MenuItem value="diamond">Diamond</MenuItem>
      </StyledSelect>
    </StyledFormControl>
  );
}

export function PamTierPlatformField(values, handleChange) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-Tier">Tier</InputLabel>
      <StyledSelect
        value={values.Tier}
        onChange={(e) => handleChange(e, "Tier")}
        label="Tier"
      >
        <MenuItem value="">
          <em>--Select Tier--</em>
        </MenuItem>
        <MenuItem value="bronze">Bronze</MenuItem>
        <MenuItem value="gold">Gold</MenuItem>
        <MenuItem value="platinum">Platinum</MenuItem>
      </StyledSelect>
    </StyledFormControl>
  );
}

export function DryRunField(values, handleChange) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-Environment">Dry Run</InputLabel>
      <StyledSelect
        value={values.DryRun}
        onChange={(e) => handleChange(e, "DryRun")}
        label="DryRun"
      >
        <MenuItem value="false">
          <em>--Dry Run?--</em>
        </MenuItem>
        <MenuItem value="true">True</MenuItem>
        <MenuItem value="false">False</MenuItem>
      </StyledSelect>
    </StyledFormControl>
  );
}

export function TeamsRelayEnabledField(
  handleChange,
  values,
  isTeamsRelayEnabled
) {
  return (
    <RadioWrapper>
      <StyledFormLabel component="legend">Teams Relay Enabled</StyledFormLabel>
      <StyledRadioGroup
        aria-label="teams-relay-enabled"
        name="TeamsRelayEnabled"
        value={values.TeamsRelayEnabled}
        onChange={(e) => handleChange(e, "TeamsRelayEnabled")}
        row
      >
        <FormControlLabel
          checked={isTeamsRelayEnabled}
          value="true"
          control={<Radio data-cy="TeamsRelayEnabled" color="primary" />}
          margin="dense"
          label="Yes"
        />
        <FormControlLabel
          checked={!isTeamsRelayEnabled}
          value="false"
          margin="dense"
          control={<Radio data-cy="TeamsRelayDisabled" color="primary" />}
          label="No"
        />
      </StyledRadioGroup>
    </RadioWrapper>
  );
}

export function TeamsClientId(handleChange, values) {
  return (
    <StyledTextField
      label="Teams Client Id"
      id="TeamsClientId"
      margin="dense"
      variant="outlined"
      onChange={(e) => handleChange(e, "TeamsClientId")}
      value={values.TeamsClientId}
    />
  );
}

export function TeamsSecret(handleChange, values) {
  return (
    <StyledTextField
      label="Teams Client Secret"
      id="TeamsClientSecret"
      margin="dense"
      variant="outlined"
      onChange={(e) => handleChange(e, "TeamsClientSecret")}
      value={values.TeamsClientSecret}
    />
  );
}

export function TeamsBotName(handleChange, values) {
  return (
    <StyledTextField
      label="Teams BotName"
      id="TeamsBotName"
      margin="dense"
      variant="outlined"
      onChange={(e) => handleChange(e, "TeamsBotName")}
      value={values.TeamsBotName}
    />
  );
}

export function EnableLandingPageField(values, handleChange) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-Region">Enable Landing Page</InputLabel>
      <StyledSelect
        value={values.EnableLandingPage}
        onChange={(e) => handleChange(e, "EnableLandingPage")}
        label="Enable Landing Page"
      >
        <MenuItem value="">
          <em>--Enable Landing Page--</em>
        </MenuItem>
        {<MenuItem value="true">Yes</MenuItem>}
        {<MenuItem value="false">No</MenuItem>}
      </StyledSelect>
    </StyledFormControl>
  );
}

export function PAMPlatformType(values, handleChange) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-Region">PAM Platform Type</InputLabel>
      <StyledSelect
        value={values.PAMPlatformType}
        onChange={(e) => handleChange(e, "PAMPlatformType")}
        label="PAM Platform Type"
      >
        <MenuItem value="">
          <em>--PAM Platform Type--</em>
        </MenuItem>
        {<MenuItem value="PAM Lite">PAM Lite</MenuItem>}
        {<MenuItem value="PAM Premium">PAM Premium</MenuItem>}
      </StyledSelect>
    </StyledFormControl>
  );
}

export function OrderTypeSelection(values, handleChange) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-Region">Order Type</InputLabel>
      <StyledSelect
        labelId="order-type-label"
        id="order-type"
        value={values.OrderType}
        onChange={(e) => handleChange(e, "OrderType")}
        label="Order Type"
      >
        <MenuItem value="">
          <em>--Select the order type--</em>
        </MenuItem>
        <MenuItem value="New">New</MenuItem>
        <MenuItem value="Existing">Existing</MenuItem>
      </StyledSelect>
    </StyledFormControl>
  );
}

function checkStackArray(StackApplicationArray, StackApplicationString) {
  const filtered = StackApplicationArray.filter(
    (addon) => addon === StackApplicationString
  );
  return filtered.length > 0;
}

function checkIfInfra360HostAvailable(StackApplicationArray) {
  const hasDetailedInfraHostWindows = StackApplicationArray.find(
    (addon) => addon === "acn-infra360host-addons-detailed-windows"
  );
  const hasDetailedInfraHostLinux = StackApplicationArray.find(
    (addon) => addon === "acn-infra360host-addons-detailed-linux"
  );
  return !!hasDetailedInfraHostWindows || !!hasDetailedInfraHostLinux;
}

export function Infra360HostDetailedLinuxCheckbox(values, handleChange) {
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={checkStackArray(
              values.StackApplication,
              "acn-infra360host-addons-detailed-linux"
            )}
            onChange={() =>
              handleChange("acn-infra360host-addons-detailed-linux")
            }
            name="acn-infra360host-addons-detailed-linux"
          />
        }
        label="Infra360 Host Detailed - Linux"
      />
    </div>
  );
}

export function Infra360HostDetailedWindowsCheckbox(values, handleChange) {
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={checkStackArray(
              values.StackApplication,
              "acn-infra360host-addons-detailed-windows"
            )}
            onChange={() =>
              handleChange("acn-infra360host-addons-detailed-windows")
            }
            name="acn-infra360host-addons-detailed-windows"
          />
        }
        label="Infra360 Host Detailed - Windows"
      />
    </div>
  );
}

export function Infra360HostWindowsCheckbox(values, handleChange) {
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={checkStackArray(
              values.StackApplication,
              "acn-infra360host-addons-windows"
            )}
            onChange={() =>
              handleChange("acn-infra360host-addons-windows")
            }
            name="acn-infra360host-addons-windows"
          />
        }
        label="Infra360 Host Windows"
      />
    </div>
  );
}

export function HostProcess360LinuxCheckbox(values, handleChange) {
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={checkStackArray(
              values.StackApplication,
              "acn-hostprocess360-linux"
            )}
            onChange={() => handleChange("acn-hostprocess360-linux")}
            name="acn-hostprocess360-linux"
          />
        }
        label="Host Process 360 - Linux"
      />
    </div>
  );
}

export function HostProcess360WindowsCheckbox(values, handleChange) {
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={checkStackArray(
              values.StackApplication,
              "acn-hostprocess360-windows"
            )}
            onChange={() => handleChange("acn-hostprocess360-windows")}
            name="acn-hostprocess360-windows"
          />
        }
        label="Host Process 360 - Windows"
      />
    </div>
  );
}

export function HostProcessWindowsCheckbox(values, handleChange) {
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={checkStackArray(
              values.StackApplication,
              "acn-hostprocess360-windows"
            )}
            onChange={() => handleChange("acn-hostprocess360-windows")}
            name="acn-hostprocess360-windows"
          />
        }
        label="Host Process Windows"
      />
    </div>
  );
}

export function Infra360HostCheckbox(values, handleChange) {
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={checkStackArray(
              values.StackApplication,
              "acn-infra360host"
            )}
            onChange={() => handleChange("acn-infra360host")}
            name="acn-infra360host"
            disabled={checkIfInfra360HostAvailable(values.StackApplication)}
          />
        }
        label="Infra360 Host"
      />
    </div>
  );
}

export function HostService360Checkbox(values, handleChange) {
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={checkStackArray(
              values.StackApplication,
              "acn-hostservice360-services"
            )}
            onChange={() => handleChange("acn-hostservice360-services")}
            name="acn-hostservice360-services"
          //disabled={checkIfInfra360HostAvailable(values.StackApplication)}
          />
        }
        label="Host Service 360"
      />
    </div>
  );
}

export function DB360OracleDBCheckbox(values, handleChange) {
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={checkStackArray(
              values.StackApplication,
              "acn-db360-oracledb"
            )}
            onChange={() => handleChange("acn-db360-oracledb")}
            name="acn-db360-oracledb"
          />
        }
        label="DB360 - OracleDB"
      />
    </div>
  );
}

export function DB360MSSQLServerCheckbox(values, handleChange) {
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={checkStackArray(
              values.StackApplication,
              "acn-db360-mssqlserver"
            )}
            onChange={() => handleChange("acn-db360-mssqlserver")}
            name="acn-db360-mssqlserver"
          />
        }
        label="DB360 - MS SQL Server"
      />
    </div>
  );
}

export function DB360MYSQLCheckbox(values, handleChange) {
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={checkStackArray(
              values.StackApplication,
              "acn-db360-mysql"
            )}
            onChange={() => handleChange("acn-db360-mysql")}
            name="acn-db360-mysql"
          />
        }
        label="DB360 mySQL"
      />
    </div>
  );
}


export function AlertCentreCheckbox(values, handleChange) {
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={checkStackArray(
              values.StackApplication,
              "acn-alertcentre360"
            )}
            disabled={true}
            onChange={() => handleChange("acn-alertcentre360")}
            name="acn-alertcentre360"
          />
        }
        label="Alert Centre"
      />
    </div>
  );
}

export function Infra360NNMiCheckbox(values, handleChange) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checkStackArray(values.StackApplication, "acn-infra360nnmi")}
          onChange={() => handleChange("acn-infra360nnmi")}
          name="acn-infra360nnmi"
        />
      }
      label="Infra360 NNMi"
    />
  );
}

export function HostPingCheckbox(values, handleChange) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checkStackArray(values.StackApplication, "acn-infra360host-ping")}
          onChange={() => handleChange("acn-infra360host-ping")}
          name="acn-infra360host-ping"
        />
      }
      label="Host Ping"
    />
  );
}

export function Infra360SolarWindsCheckbox(values, handleChange) {
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={checkStackArray(values.StackApplication, "acn-infra360solarwinds")}
            onChange={() => handleChange("acn-infra360solarwinds")}
            name="acn-infra360solarwinds"
          />
        }
        label="Infra360 SolarWinds"
      />
    </div>
  );
}

export function Infra360SNMPTrapsCheckbox(values, handleChange) {
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={checkStackArray(values.StackApplication, "acn-infra360snmp-traps")}
            onChange={() => handleChange("acn-infra360snmp-traps")}
            name="acn-infra360snmp-traps"
          />
        }
        label="Infra360 SNMP – Traps"
      />
    </div>
  );
}

export function DB360PostgresSQLCheckbox(values, handleChange) {
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={checkStackArray(values.StackApplication, "acn-db360-postgresql")}
            onChange={() => handleChange("acn-db360-postgresql")}
            name="acn-db360-postgresql"
          />
        }
        label="DB360 Postgre SQL"
      />
    </div>
  );
}

export function URL360APICheckbox(values, handleChange) {
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={checkStackArray(values.StackApplication, "acn-url360-api")}
            onChange={() => handleChange("acn-url360-api")}
            name="acn-url360-api"
          />
        }
        label="URL360 – API"
      />
    </div>
  );
}


export function Cloud360AWSCheckbox(values, handleChange) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checkStackArray(
            values.StackApplication,
            "acn-cloud360-addons-aws"
          )}
          onChange={() => handleChange("acn-cloud360-addons-aws")}
          name="acn-cloud360-addons-aws"
        />
      }
      label="Cloud360 AWS"
    />
  );
}

export function Cloud360AzureCheckbox(values, handleChange) {
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={checkStackArray(
              values.StackApplication,
              "acn-cloud360-addons-azure"
            )}
            onChange={() => handleChange("acn-cloud360-addons-azure")}
            name="acn-cloud360-addons-azure"
          />
        }
        label="Cloud360 - Azure"
      />
    </div>

  );
}

export function DeployServiceNowIDField(handleChange, values, isDeployServiceNowID) {
  return (
    <div>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={isDeployServiceNowID} />}
          label="Service Now"
          name="DeployServiceNowID"
          onChange={(e) => handleChange(e, "DeployServiceNowID")}
        />
      </FormGroup>
    </div>
  );
}

export function DeployJiraIDField(handleChange, values, isDeployJiraID) {
  return (
    <div>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={isDeployJiraID} />}
          label="Jira"
          name="DeployJiraID"
          onChange={(e) => handleChange(e, "DeployJiraID")}
        />
      </FormGroup>
    </div>
  );
}

export function DeployEmailIDField(handleChange, values, isDeployEmailID) {
  return (
    <div>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={isDeployEmailID} />}
          label="Email"
          name="DeployEmailID"
          onChange={(e) => handleChange(e, "DeployEmailID")}
        />
      </FormGroup>
    </div>
  );
}

export function DeployATRIDField(handleChange, values, isDeployATRID) {
  return (
    <div>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={isDeployATRID} />}
          label="ATR"
          name="DeployATRID"
          onChange={(e) => handleChange(e, "DeployATRID")}
        />
      </FormGroup>
    </div>
  );
}

export function KMApplicationField(handleChange, values) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-geographic-area">
        KM Applications
      </InputLabel>
      <StyledSelect
        value={values.KMApps}
        onChange={(e) => handleChange(e, "KMApps")}
        label="KM Applications"
      >
        <MenuItem value="">
          <em>--Select a KM Application--</em>
        </MenuItem>
        <MenuItem value="KlewerGenAi">Klewer Gen AI</MenuItem>
        {/* <MenuItem value="KlewerNonGenAi">Klewer non-Gen AI</MenuItem>
        <MenuItem value="KlewerGenAi-Quasarpp">Klewer Gen AI + Quasar++</MenuItem>
        <MenuItem value="QuasarGenAi-QuasarppGenAi">Quasar Gen AI + Quasar++ Gen AI</MenuItem> */}
        <MenuItem value="KlewerGenAi-QuasarGenAi-Quasarpp">Klewer Gen AI + Quasar Gen AI + Quasar++</MenuItem>
      </StyledSelect>
    </StyledFormControl>
  );
}

export function UTKMTierPlatformField(values, handleChange) {
  return (
    <StyledFormControl variant="outlined" margin="dense">
      <InputLabel htmlFor="outlined-Tier">Tier</InputLabel>
      <StyledSelect
        value={values.Tier}
        onChange={(e) => handleChange(e, "Tier")}
        label="Tier"
      >
        <MenuItem value="">
          <em>--Select Tier--</em>
        </MenuItem>
        <MenuItem value="platinum">Platinum</MenuItem>
        <MenuItem value="gold">Gold</MenuItem>
      </StyledSelect>
    </StyledFormControl>
  );
}


// export function DeployServiceNowIDField(handleChange, values, isDeployServiceNowID) {
//   return (
//     <RadioWrapper>
//       <StyledFormLabel component="legend">Service Now Integration</StyledFormLabel>
//       <StyledRadioGroup
//         aria-label="deploy-service-now-id"
//         name="DeployServiceNowID"
//         value={values.DeployServiceNowID}
//         onChange={(e) => handleChange(e, "DeployServiceNowID")}
//         row
//       >
//         <FormControlLabel
//           checked={isDeployServiceNowID}
//           value="True"
//           control={<Radio data-cy="DeployServiceNowIDYes" color="primary" />}
//           margin="dense"
//           label="Yes"
//         />
//         <FormControlLabel
//           checked={!isDeployServiceNowID}
//           value="False"
//           margin="dense"
//           control={<Radio data-cy="DeployServiceNowIDNo" color="primary" />}
//           label="No"
//         />
//       </StyledRadioGroup>
//     </RadioWrapper>
//   );
// }

// export function DeployJiraIDField(handleChange, values, isDeployJiraID) {
//   return (
//     <RadioWrapper>
//       <StyledFormLabel component="legend">Jira Integration</StyledFormLabel>
//       <StyledRadioGroup
//         aria-label="deploy-jira-id-integration"
//         name="DeployJiraID"
//         value={values.DeployJiraID}
//         onChange={(e) => handleChange(e, "DeployJiraID")}
//         row
//       >
//         <FormControlLabel
//           checked={isDeployJiraID}
//           value="True"
//           control={<Radio data-cy="DeployJiraIDYes" color="primary" />}
//           margin="dense"
//           label="Yes"
//         />
//         <FormControlLabel
//           checked={!isDeployJiraID}
//           value="False"
//           margin="dense"
//           control={<Radio data-cy="DeployJiraIDNo" color="primary" />}
//           label="No"
//         />
//       </StyledRadioGroup>
//     </RadioWrapper>
//   );
// }

// export function DeployEmailIDField(handleChange, values, isDeployEmailID) {
//   return (
//     <RadioWrapper>
//       <StyledFormLabel component="legend">Email Integration</StyledFormLabel>
//       <StyledRadioGroup
//         aria-label="deploy-email-id-integration"
//         name="DeployEmailID"
//         value={values.DeployEmailID}
//         onChange={(e) => handleChange(e, "DeployEmailID")}
//         row
//       >
//         <FormControlLabel
//           checked={isDeployEmailID}
//           value="True"
//           control={<Radio data-cy="DeployEmailIDYes" color="primary" />}
//           margin="dense"
//           label="Yes"
//         />
//         <FormControlLabel
//           checked={!isDeployEmailID}
//           value="False"
//           margin="dense"
//           control={<Radio data-cy="DeployEmailIDNo" color="primary" />}
//           label="No"
//         />
//       </StyledRadioGroup>
//     </RadioWrapper>
//   );
// }

// export function DeployATRIDField(handleChange, values, isDeployATRID) {
//   return (
//     <RadioWrapper>
//       <StyledFormLabel component="legend">ATR Integration</StyledFormLabel>
//       <StyledRadioGroup
//         aria-label="deploy-atr-id-integration"
//         name="DeployATRID"
//         value={values.DeployATRID}
//         onChange={(e) => handleChange(e, "DeployATRID")}
//         row
//       >
//         <FormControlLabel
//           checked={isDeployATRID}
//           value="True"
//           control={<Radio data-cy="DeployATRIDYes" color="primary" />}
//           margin="dense"
//           label="Yes"
//         />
//         <FormControlLabel
//           checked={!isDeployATRID}
//           value="False"
//           margin="dense"
//           control={<Radio data-cy="DeployATRIDNo" color="primary" />}
//           label="No"
//         />
//       </StyledRadioGroup>
//     </RadioWrapper>
//   );
// }
