import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import TextsmsIcon from "@material-ui/icons/Textsms";
import { MenuItem, InputLabel } from "@material-ui/core";
import { StyledFormControl, StyledSelect, StyledTooltip, TextHelperTypography, TextHelperWrapper } from "../FuiStyles";
import { longTextEndDate, quickConfigurationTextHelper } from "../Constants/LongText";
import { DateTextFieldCommonComponent } from "../DateTextFieldCommonComponent/DateTextFieldCommonComponent";
import {
  checkWBSECode,
  checkDailyDataVolume,
  checkBenefitsExpected,
} from "./CommonFunctions";
import GeneralForm from "./GeneralForm";
import * as FormField from "./IndividualFormFields";
import * as PlatformFormField from "./IndividualFormFields";
import * as fuiRedux from "../../../redux/fui/index";
import {
  fetchExternalClientDetails,
} from "../../../redux/fui/index";

/**
 * On premise Service Form Form
 * @author Isabel Sprengers
 * @version 2.0
 * @see Used in component: PlatformLicenseStepper
 */
const OnPremiseService = ({
  history, // get route history
  values, // the value would be changed by user's input
  updateOPSForm, //the function to update OPSForm values which is from redux
  selectedEnvironment,
  getSelectedEnvironment,
  configurationType,
  minDate,
  maxDate,
  getServiceEndDate,
  isServiceEndDateInvalid,
  createdBy,
  setIsAllValid,
  isExternalClientLoading,
  externalClientData,
  externalClientError,
  fetchExternalClientDetails,
  dailyDataVolumeData,
  getNotesValue,
  notesValidations,
}) => {

  const config = configurationType === "quick";
  // check whether values are valid
  const [isWBSECodeValid, setIsWBSECodeValid] = useState(true);
  // check whether WBSECode is equal to MD & I WBSE
  const [isWBSECodeMDIValid, setIsWBSECodeMDIValid] = useState(true);
  const [isProjectNameValid, setIsProjectNameValid] = useState(true);
  const [isDailyDataVolumeValid, setIsDailyDataVolumeValid] = useState(true);
  const [isBenefitsExpectedValid, setIsBenefitsExpectedValid] = useState(true);
  const [isInfrastructureType, setIsInfrastructureType] = useState(true);
  const [selectedAsset, setSelectedAsset] = useState([]);
  const [selectedDataVolumeSizes, setSelectedDataVolumeSizes] = useState([]);
  const [totalSelectedDailyDataVolume, setTotalSelectedDailyDataVolume] = useState(0);

  const dailyDataVolumes = localStorage.getItem("dailyDataVolumes")
  const dailyDataVolumesSplit = dailyDataVolumes.split(",")
  const dailyDataVolumesList = [];
  dailyDataVolumesSplit.forEach(ele => dailyDataVolumesList.push(+ele))
  const dataVolumesSort = dailyDataVolumesList.sort((a, b) => a - b);
  const dataVolumes = dataVolumesSort.filter(item => {
    return (typeof item === "number") && item <= 120;
  });

  const assets = "AiOps Automation for Splunk Cluster,Automatic Ticket Resolver,Automatic Ticket Resolver CaaS,Automation Journey,Automation Planner,ChatBot,Cloud Chargeback,CloudChaser,Cloud Security and Compliance Checker,Mainframe 360,myWizard AiOps Automatic Ticket Assignment (ATA),myWizard AiOps Batch,myWizard AiOps RPA,Report Extractor,Salesforce 360,Service Integrator,Smart Alerts,Smart Capacity Prediction,StormWatch,Ticket Analysis Application,Turing,myBuddy,DevOps Analytics,Splunk Watch,Estimator,Cloud Asset Management (CAM),Oracle360,Codeless Automation Accelerator (CAF),Stormseer,Supply Chain Control Tower,Cisco-EOX,Automation Platform Monitoring (AuPM),EventOps,Security and Operations Governance RDD (SOG_RDD),Security and Operations Governance Brand Risk (SOG_Brandrisk),Intelligent Opportunity Miner - IOM,Virtual Data Scientist - VDS,AI Core (ingrAIn),myWizard AIOps BPM Salesforce,Embedded RPA Suites,myWizard AiOps for SAP,Stack Monitoring Suite,myWizard AiOps Appstore,HR Buddy"
  const assetsList = assets.split(",");
  const sortAssetsList = assetsList.sort();

  const [sapContractCodeLength, setSapContractCodeLength] = useState("0");

  if (config) {
    updateOPSForm("ClientName", "")
    updateOPSForm("EndToEndUId", "")
    updateOPSForm("Company", "")
    updateOPSForm("AvanadeCountry", "")
    updateOPSForm("AccentureCountry", "")
  }

  if (!config) {
    if (externalClientData) {
      updateOPSForm("ClientName", externalClientData[0])
      updateOPSForm("EndToEndUId", externalClientData[1])
      updateOPSForm("Company", externalClientData[2])
      updateOPSForm("AvanadeCountry", externalClientData[3])
      updateOPSForm("AccentureCountry", externalClientData[4])
    }
  }

  // const sapValue = document.getElementById("SAPContractID");
  // if (sapValue) {
  //   if (sapValue.value === "") {
  //     document.getElementById("clientDetails").style.display = "none";
  //     const verifiedIcon = document.getElementById("verifiedIcon")
  //     if (verifiedIcon) {
  //       verifiedIcon.style.display = "none";
  //     }
  //   } else {
  //     document.getElementById("clientDetails").style.display = "block";
  //   }
  // }

  // change validation status
  // useEffect(() => {
  //   (isWBSECodeValid || isDailyDataVolumeValid || isBenefitsExpectedValid) &&
  //   !isServiceEndDateInvalid
  //     ? setIsAllValid(true)
  //     : setIsAllValid(false);
  // });

  if (!config) {
    (isDailyDataVolumeValid ||
      isBenefitsExpectedValid) &&
      !isServiceEndDateInvalid && sapContractCodeLength == 10 && !externalClientError && isWBSECodeValid && isWBSECodeMDIValid && isInfrastructureType && isProjectNameValid
      ? setIsAllValid(true)
      : setIsAllValid(false);
  } else { //internal user
    (
      isDailyDataVolumeValid ||
      isBenefitsExpectedValid) &&
      !isServiceEndDateInvalid && isWBSECodeValid && isWBSECodeMDIValid && isInfrastructureType && isProjectNameValid
      ? setIsAllValid(true)
      : setIsAllValid(false);
  }

  const isAAAMTermsConditions = values.AAAMTermsConditions === "yes";
  updateOPSForm("ConfigurationType", configurationType);

  // get user's input
  const handleChange = (event, fieldName) => {
    if (fieldName === "TentativeServiceEndDate") {
      updateOPSForm(fieldName, event);
      getServiceEndDate(event);
    } else {
      const value = event.target.value;
      event.preventDefault();
      updateOPSForm(fieldName, value);
      if (fieldName === "Environment") {
        getSelectedEnvironment(value);
      }
      if (fieldName === "WBSEcode") {
        const wbsevalue = value.toUpperCase()
        if (wbsevalue.trim() === "CBYDQ001") {
          setIsWBSECodeMDIValid(false)
        }
        setIsWBSECodeValid(checkWBSECode(value));
      }
      if (fieldName === "ProjectName") {
        if (value.length > 32 || value.length < 3) {
          setIsProjectNameValid(false)
        } else {
          setIsProjectNameValid(true)
        }
      }
      if (fieldName === "InfrastructureType") {
        if (value == "") {
          setIsInfrastructureType(false);
        } else {
          setIsInfrastructureType(true);
        }
      }
      if (fieldName === "RequestNotes") {
        if (value.length > 0 && value.length < 247) {
          getNotesValue({ emptyString: false, maxLength: false });
        } else if (value.length === 0) {
          getNotesValue({ emptyString: true, maxLength: false });
        } else if (value.length > 246) {
          getNotesValue({ emptyString: false, maxLength: true });
        }
      }
      // if (fieldName === "DailyDataVolume") {
      //   if (value[0] === "0") {
      //     setIsDailyDataVolumeValid(false);
      //   } else if (parseInt(value, 10) > 200) {
      //     setIsDailyDataVolumeValid(false);
      //   } else {
      //     setIsDailyDataVolumeValid(checkDailyDataVolume(value));
      //   }
      // }
      if (fieldName === "BenefitsExpected") {
        if (value[0] === "0") {
          setIsBenefitsExpectedValid(false);
        } else {
          setIsBenefitsExpectedValid(checkBenefitsExpected(value));
        }
      }
      if (fieldName === "TentativeServiceEndDate") {
        getServiceEndDate(value);
      }
      if (fieldName === "SAPContractID") {
        let sapLength = value.length
        setSapContractCodeLength(sapLength)
        if (sapLength == 10) {
          fetchExternalClientDetails(value)
        }
      }
      if (externalClientData) {
        updateOPSForm("ClientName", externalClientData[0])
        updateOPSForm("EndToEndUId", externalClientData[1])
        updateOPSForm("Company", externalClientData[2])
        updateOPSForm("AvanadeCountry", externalClientData[3])
        updateOPSForm("AccentureCountry", externalClientData[4])
      }
      if (fieldName === "AiOpsAsset") {
        setSelectedAsset(value)
      }
      if (fieldName === "DailyDataVolume") {
        var total = 0;
        for (var i in value) {
          total += value[i];
        }
        setTotalSelectedDailyDataVolume(total)
        setSelectedDataVolumeSizes(value)
        updateOPSForm("SplitDataVolume", value);
        updateOPSForm("DailyDataVolume", total);
        if (total != 0) {
          setIsDailyDataVolumeValid(true)
        } else {
          setIsDailyDataVolumeValid(false)
        }
      }

      if (value === "AiOps Asset") {
        updateOPSForm("Custom", "")
      }

      if (value === "Custom") {
        setSelectedAsset([])
        updateOPSForm("AiOpsAsset", [])
      }

      // if (fieldName === "LicensePurpose") {
      //   if (value == "AiOpsAsset") {
      //     updateOPSForm("Custom", "");        
      //   }
      //   if (value == "Custom") {
      //     updateOPSForm("AiOpsAsset", "");        
      //   }
      // }
    }
  };

  // const handleChangeSAPContractId = (event, fieldName) => {
  //   if (fieldName === "SAPContractID") {
  //     const value = event.target.value;
  //     event.preventDefault();
  //     updateOPSForm(fieldName, value);
  //   } 
  // };

  const handleChangeMobileNo = (event, fieldName) => {
    const re = /^[0-9]{0,}$/;
    const value = event.target.value;
    re.test(value) && updateOPSForm(fieldName, value);
  };



  return (
    <>
      <GeneralForm
        FormId={11}
        ClientContractFields={
          <div style={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={2}
            >
              {!config &&
                <Grid item xs={4}>
                  {PlatformFormField.SAPContractIDPlatformField(values, handleChange, externalClientError, isExternalClientLoading, sapContractCodeLength)}
                </Grid>}

              {sapContractCodeLength == 10 && sapContractCodeLength != 0 && !isExternalClientLoading && !externalClientError
                ? !config &&
                <Grid item xs={4}> {PlatformFormField.ClientNamePlatformField(values, handleChange, externalClientData[0])}</Grid>
                : ""
              }


              {sapContractCodeLength == 10 && !isExternalClientLoading && !externalClientError ?
                !config &&
                <Grid item xs={4}> {PlatformFormField.EndToEndUIdPlatformField(values, handleChange, externalClientData[1])}</Grid>
                : ""
              }

              <Grid item xs={4}>
                {FormField.ProjectNameField(handleChange, values, isProjectNameValid)}
              </Grid>
              <Grid item xs={4}>
                {FormField.WBSECodeField(handleChange, values, isWBSECodeValid, isWBSECodeMDIValid)}
              </Grid >
              <Grid item xs={6}>
                {FormField.SecondaryContact(handleChange, values, config)}
              </Grid>
              <Grid item xs={6}>
                {FormField.InfrastructureTypeField(handleChange, values)}
              </Grid>
              <Grid item xs={6}>
                {FormField.LicensePurposeField(handleChange, values)}
              </Grid>
              {values.LicensePurpose === "AiOps Asset" &&
                <Grid item xs={6}>
                  {FormField.AiOpsAssetField(handleChange, values, sortAssetsList, selectedAsset)}
                </Grid>}
              {values.LicensePurpose === "Custom" &&
                <Grid item xs={6}>
                  {FormField.CustomField(handleChange, values)}
                </Grid>}

              <Grid item xs={12}>
                {PlatformFormField.RequestNotesPlatformField(
                  values,
                  handleChange,
                  notesValidations
                )}
              </Grid>
            </Grid>
          </div>
        }
        EnvironmentFields={
          <div>
            <div style={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={2}
              >

                <Grid item xs={3}>
                  <StyledFormControl variant="outlined" margin="dense">
                    <InputLabel htmlFor="outlined-Deployment-Region">
                      Deployment Region
                    </InputLabel>
                    <StyledSelect
                      value={values.DeploymentRegion}
                      onChange={(e) => handleChange(e, "DeploymentRegion")}
                      label="Deployment Region"
                    >
                      <MenuItem value="">
                        <em>--Select Deployment Region--</em>
                      </MenuItem>
                      <MenuItem value="singapore">Singapore</MenuItem>
                    </StyledSelect>
                  </StyledFormControl>
                </Grid>
                <Grid item xs={6}>
                  {FormField.DailyDataVolumeSplunkLicensePlatformField(handleChange, values, dataVolumes, selectedDataVolumeSizes, totalSelectedDailyDataVolume)}
                </Grid>
                <Grid item xs={3}>
                  {PlatformFormField.EnvironmentPlatformField(values, handleChange)}
                </Grid>
              </Grid>
            </div>
          </div>
        }
        ApplicationFields={
          <div style={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={2}
            >
              <Grid item xs={6}>
                <StyledTooltip
                  title={longTextEndDate}
                  placement="left-start"
                  noValidate
                >
                  <DateTextFieldCommonComponent
                    tentativeServiceEndDate={values.TentativeServiceEndDate}
                    selectedEnvironment={selectedEnvironment}
                    isServiceEndDateInvalid={isServiceEndDateInvalid}
                    minDate={minDate}
                    maxDate={maxDate}
                    handleChange={handleChange}
                  />
                </StyledTooltip>
              </Grid>
            </Grid>
          </div>
        }
        history={history}
      ></GeneralForm>

    </>
  );
};

const MSTP = (state, ownProps) => ({
  email: state.auth.userDetails.email,
  configurationType: state.fui.configurationType,
  values: state.fui.OPSForm,
  externalClientData: state.fui.externalClientData,
  externalClientError: state.fui.externalClientError,
  isExternalClientLoading: state.fui.isExternalClientLoading,
  dailyDataVolumeData: state.home.dailyDataVolumeData,
});

export default connect(MSTP, {
  updateOPSForm: fuiRedux.updateOPSForm, fetchExternalClientDetails,
})(
  withRouter(OnPremiseService)
);
