import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchExternalClientDetails,
} from "../../../redux/fui/index";
import { checkRootExisted } from "../../../redux/fui";
import { StyledTooltip } from "../FuiStyles";
import { longTextEndDate, rootExistedHint, rootWrongFormatHint } from "../Constants/LongText";
import { useDebounce } from "../../../helper/customizedHooks";
import { DateTextFieldCommonComponent } from "../DateTextFieldCommonComponent/DateTextFieldCommonComponent";
import * as FormField from "./IndividualFormFields";
import * as PlatformFormField from "./IndividualFormFields";
import { checkServiceNowTicket, checkWBSECode } from "./CommonFunctions";
import GeneralForm from "./GeneralForm";
import * as fuiRedux from "../../../redux/fui/index";
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Grid
  //   FormHelperText,
} from "@material-ui/core";

/**
 * Automatic Ticket Resolver Form
 * @author Isabel Sprengers
 * @version 2.0
 * @see Used in component: PlatformLicenseStepper
 */

const PamInstance = ({
  values, // the value would be changed by user's input
  updatePAMForm, //the function to update DPForm values which is from redux
  createdBy, // user's EID
  checkDomainAvaliable, // the function that check whether DomainContextRoot exist or not from redux
  isRootExist, // the value that show whether DomainContextRoot exist or not from redux
  setIsAllValid, // the function to check whether all validations are satisfieds
  selectedEnvironment,
  minDate,
  maxDate,
  isServiceEndDateInvalid,
  getSelectedEnvironment,
  getServiceEndDate,
  getNotesValue,
  notesValidations,
  configurationType,
  classes,
  history,
  vendorName,
  email,
  idToken,
  isExternalClientLoading,
  externalClientData,
  externalClientError,
  fetchExternalClientDetails,
  isLoading
}) => {

  // open the dialog when user choose Yes in GDPR
  const config = configurationType === "quick";

  //check whether ServiceNowTicket is valid
  const [isServiceNowTicketValid, setIsServiceNowTicketValid] = useState(true);

  // check whether WBSECode and MonthlyTicketVolume are valid
  const [isWBSECodeValid, setIsWBSECodeValid] = useState(true);
  // check whether WBSECode is equal to MD & I WBSE
  const [isWBSECodeMDIValid, setIsWBSECodeMDIValid] = useState(true);
  const [isProjectNameValid, setIsProjectNameValid] = useState(true);
  const [isDialogFlowDevToken, setIsDialogFlowDevToken] = useState(false);
  const [isDialogFlowClientToken, setIsDialogFlowClientToken] = useState(false);
  const [isADGValid, setADGValid] = useState(false);
  const isGDPR = values.GDPR === "yes";
  const [sapContractCodeLength, setSapContractCodeLength] = useState("0");
  const [domainContextRoot, setDomainContextRoot] = useState("[__]");
  const [regionCode, setRegionCode] = useState("[__]");
  const [customURL, setCustomURL] = useState("https://[__].mywizard-aiops.accenture.com/[__]");

  useEffect(() => {
    if (config) {
      setDomainContextRoot("[__]");
      setRegionCode("[__]");
    }
  }, [config]);

  if (config) {
    updatePAMForm("ClientName", "")
    updatePAMForm("EndToEndUId", "")
    updatePAMForm("Company", "")
    updatePAMForm("AvanadeCountry", "")
    updatePAMForm("AccentureCountry", "")
  }

  if (!config) {
    if (externalClientData) {
      updatePAMForm("ClientName", externalClientData[0])
      updatePAMForm("EndToEndUId", externalClientData[1])
      updatePAMForm("Company", externalClientData[2])
      updatePAMForm("AvanadeCountry", externalClientData[3])
      updatePAMForm("AccentureCountry", externalClientData[4])
    }
  }

  // const sapValue = document.getElementById("SAPContractID");
  // if (sapValue) {
  //   if (sapValue.value === "") {
  //     document.getElementById("clientDetails").style.display = "none";
  //     const verifiedIcon = document.getElementById("verifiedIcon")
  //     if (verifiedIcon) {
  //       verifiedIcon.style.display = "none";
  //     }
  //   } else {
  //     document.getElementById("clientDetails").style.display = "block";
  //   }
  // }

  const [pamApps, setPamApps] = React.useState({
    // IOM: false,
    AP: false,
    AJ: false,
    GTR: false,
    // VDS: false,
    // Klewer: false,
  });

  updatePAMForm("VendorName", vendorName);

  // const { IOM, AP, AJ, GTR } = pamApps;
  const { AP, AJ, GTR } = pamApps;

  const handlePamAppsChange = (event) => {
    if (event.target.name === "AJ") {
      setPamApps({
        ...pamApps,
        [event.target.name]: event.target.checked,
        AP: event.target.checked,
        // IOM: event.target.checked,
      });
    } else if (event.target.name === "AP") {
      setPamApps({
        ...pamApps,
        [event.target.name]: event.target.checked,
        // IOM: event.target.checked,
      });
    } else {
      setPamApps({ ...pamApps, [event.target.name]: event.target.checked });
    }
  };

  if (!config) {
    // ( isDialogFlowClientToken ||
    // isDialogFlowDevToken) &&
    isADGValid &&
      !isServiceEndDateInvalid && sapContractCodeLength == 10 && !externalClientError && isWBSECodeValid && isWBSECodeMDIValid && isServiceNowTicketValid && isProjectNameValid
      ? setIsAllValid(true)
      : setIsAllValid(false);
  } else {
    (isServiceNowTicketValid ||
      isDialogFlowClientToken ||
      isDialogFlowDevToken) &&
      isADGValid &&
      !isServiceEndDateInvalid && isWBSECodeValid && isWBSECodeMDIValid && isProjectNameValid
      ? setIsAllValid(true)
      : setIsAllValid(false);
  }

  // change validation status
  // (isServiceNowTicketValid ||
  //   isWBSECodeValid || 
  //   isDialogFlowClientToken || 
  //   isDialogFlowDevToken) &&
  //   isADGValid &&
  // !isServiceEndDateInvalid
  //   ? setIsAllValid(true)
  //   : setIsAllValid(false);

  // user can only input lower case letter and numbers in DomainContextRoot
  const handleChangeRoot = (event, fieldName) => {
    const re = /^[a-z0-9]*$/;
    const value = event.target.value;
    re.test(value) && updatePAMForm(fieldName, value);
    setDomainContextRoot(value)
  };

  const handleChangeRootSuggestions = (event, value, fieldName) => {
    const re = /^[a-z0-9]*$/;
    // const value = event.target.value;
    re.test(value) && updatePAMForm(fieldName, value);
    setDomainContextRoot(value)
  };

  // get user's input
  const handleChange = (event, fieldName) => {
    if (fieldName === "TentativeServiceEndDate") {
      updatePAMForm(fieldName, event);
      getServiceEndDate(event);
    } else {
      const { value } = event.target;
      event.preventDefault();
      updatePAMForm(fieldName, value);
      if (fieldName === "WBSEcode") {
        const wbsevalue = value.toUpperCase()
        if (wbsevalue.trim() === "CBYDQ001") {
          setIsWBSECodeMDIValid(false)
        }
        setIsWBSECodeValid(checkWBSECode(value));
      }
      if (fieldName === "ProjectName") {
        if (value.length > 32 || value.length < 3) {
          setIsProjectNameValid(false)
        } else {
          setIsProjectNameValid(true)
        }
      }
      if (fieldName === "RITM") {
        setIsServiceNowTicketValid(checkServiceNowTicket(value));
      }
      if (fieldName === "DialogFlowDevToken") {
        if (value.length > 0) {
          setIsDialogFlowDevToken(true);
        } else {
          setIsDialogFlowDevToken(false);
        }
      }
      if (fieldName === "DialogFlowClientToken") {
        if (value.length > 0) {
          setIsDialogFlowClientToken(true);
        } else {
          setIsDialogFlowClientToken(false);
        }
      }
      if (fieldName === "Environment") {
        getSelectedEnvironment(value);
      }
      if (fieldName === "TentativeServiceEndDate") {
        getServiceEndDate(value);
      }
      if (fieldName === "RequestNotes") {
        if (value.length > 0 && value.length < 247) {
          getNotesValue({ emptyString: false, maxLength: false });
        } else if (value.length === 0) {
          getNotesValue({ emptyString: true, maxLength: false });
        } else if (value.length > 246) {
          getNotesValue({ emptyString: false, maxLength: true });
        }
      }

      if (fieldName === "AdminDistributionGroup" && value.length > 0) {
        setADGValid(true);
      } else if (fieldName === "AdminDistributionGroup" && value.length <= 0) {
        setADGValid(false);
      }

      if (fieldName === "SAPContractID") {
        let sapLength = value.length
        setSapContractCodeLength(sapLength)
        if (sapLength == 10) {
          fetchExternalClientDetails(value)
        }
      }
      if (fieldName === "DeploymentRegion") {
        if (value == "sydney") {
          setRegionCode("dh")
        } else if (value == "virginia") {
          setRegionCode("dh1")
        } else if (value == "frankfurt") {
          setRegionCode("dh2")
        } else if (value == "singapore") {
          setRegionCode("dh3")
        } else if (value == "tokyo") {
          setRegionCode("dh4")
        } else if (value == "ireland") {
          setRegionCode("dh5")
        } else if (value == "london") {
          setRegionCode("dh6")
        } else {
          setRegionCode("[__]")
        }
      }
    }
  };

  const isAAAMTermsConditions = values.AAAMTermsConditions === "yes";
  updatePAMForm("ConfigurationType", configurationType);
  //   const isDialogFlow = values.DialogFlow === "enabled";

  // it would check whether the root has existed from DB once user stop typing longer than 0.3s
  const debouncedValue = useDebounce(domainContextRoot, 300);
  useEffect(() => {
    debouncedValue && checkDomainAvaliable(debouncedValue);
  }, [debouncedValue]);

  useEffect(() => {
    var res = [];
    for (const [key, value] of Object.entries(pamApps)) {
      if (value) {
        res.push(key);
      }
    }
    updatePAMForm("PAMApps", res);
  }, [pamApps]);

  return (
    <>
      <GeneralForm
        FormId={0}
        ClientContractFields={
          <div style={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={2}
            >
              {!config &&
                <Grid item xs={4}>
                  {PlatformFormField.SAPContractIDPlatformField(values, handleChange, externalClientError, isExternalClientLoading, sapContractCodeLength)}
                </Grid>}

              {sapContractCodeLength == 10 && sapContractCodeLength != 0 && !isExternalClientLoading && !externalClientError
                ? !config &&
                <Grid item xs={4}> {PlatformFormField.ClientNamePlatformField(values, handleChange, externalClientData[0])}</Grid>
                : ""
              }


              {sapContractCodeLength == 10 && !isExternalClientLoading && !externalClientError ?
                !config &&
                <Grid item xs={4}> {PlatformFormField.EndToEndUIdPlatformField(values, handleChange, externalClientData[1])}</Grid>
                : ""
              }

              <Grid item xs={4}>
                {FormField.ProjectNameField(handleChange, values, isProjectNameValid)}
              </Grid>
              <Grid item xs={4}>
                {FormField.WBSECodeField(handleChange, values, isWBSECodeValid, isWBSECodeMDIValid)}
              </Grid >
              {!config &&
                <Grid item xs={4}>
                  {FormField.ServiceNowTicket(handleChange, values, isServiceNowTicketValid)}
                </Grid>}
              <Grid item xs={4}>
                {FormField.SecondaryContact(handleChange, values, config)}
              </Grid>

              <Grid item xs={12}>
                {PlatformFormField.RequestNotesPlatformField(
                  values,
                  handleChange,
                  notesValidations
                )}
              </Grid>
            </Grid>
          </div>
        }
        EnvironmentFields={
          <div>
            {!config ? <p> Expected Custom URL: <span style={{ color: " #0041F0" }}>https://{regionCode}.mywizard-aiops.com/{domainContextRoot}</span></p> : ""}
            <div style={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={2}
              >

                <Grid item xs={12}>
                  {PlatformFormField.GDPRPlatformField(
                    values,
                    handleChange,
                    isGDPR,
                    classes
                  )}
                </Grid>

                <Grid item xs={3}>
                  {PlatformFormField.DeploymentRegionPlatformField(
                    values,
                    handleChange,
                    isGDPR
                  )}
                </Grid>

                <Grid item xs={6}>
                  {!config ? PlatformFormField.DomainContextRootPlatformFieldClient(
                    values,
                    handleChangeRootSuggestions,
                    // handleChangeRoot,
                    isRootExist,
                    rootExistedHint,
                    rootWrongFormatHint,
                    externalClientData,
                    domainContextRoot,
                    isLoading
                  ) : PlatformFormField.DomainContextRootPlatformField(
                    values,
                    handleChangeRoot,
                    isRootExist,
                    rootExistedHint,
                    isLoading
                  )}
                </Grid>

                <Grid item xs={3}>
                  {PlatformFormField.EnvironmentPlatformField(values, handleChange)}
                </Grid>
                <Grid item xs={3}>
                  {PlatformFormField.PamTierPlatformField(values, handleChange)}
                </Grid>
              </Grid>
            </div>
          </div>
        }
        ApplicationFields={
          <div style={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={2}
            >
              <Grid item xs={6}>
                {FormField.AdminDistributionGroupField(handleChange, values)}
              </Grid>
              {!config &&
                <Grid item xs={6}>
                  {FormField.UsersDistributionGroupField(handleChange, values)}
                </Grid>
              }
              <Grid item xs={6}>
                <StyledTooltip
                  title={longTextEndDate}
                  placement="left-start"
                  noValidate
                >
                  <DateTextFieldCommonComponent
                    tentativeServiceEndDate={values.TentativeServiceEndDate}
                    selectedEnvironment={selectedEnvironment}
                    isServiceEndDateInvalid={isServiceEndDateInvalid}
                    minDate={minDate}
                    maxDate={maxDate}
                    handleChange={handleChange}
                  />
                </StyledTooltip>
              </Grid>
              <Grid item xs={6}>
                {FormField.PAMPlatformType(values, handleChange)}
              </Grid>
              {config &&
                <Grid item xs={6}>
                </Grid>}
              {values.PAMPlatformType === "PAM Premium" && (
                <Grid item xs={6}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend" style={{ paddingTop: "10px" }}>
                      PAM Applications
                    </FormLabel>
                    <FormGroup>
                      {/* <FormControlLabel
                        style={{ paddingTop: "10px" }}
                        control={
                          <Checkbox
                            checked={IOM}
                            onChange={handlePamAppsChange}
                            name="IOM"
                            disabled={pamApps["AJ"] || pamApps["AP"]}
                          />
                        }
                        label="Intelligent Opportunity Miner"
                      /> */}
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={AP}
                            onChange={handlePamAppsChange}
                            name="AP"
                            disabled={pamApps["AJ"]}
                          />
                        }
                        label="Automation Planner"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={AJ}
                            onChange={handlePamAppsChange}
                            name="AJ"
                          />
                        }
                        label="Automation Journey"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={GTR}
                            onChange={handlePamAppsChange}
                            name="GTR"
                          />
                        }
                        label="Guided Ticket Resolver"
                      />
                      {/* <FormControlLabel
                        control={
                          <Checkbox
                            checked={VDS}
                            onChange={handlePamAppsChange}
                            name="VDS"
                          />
                        }
                        label="Virtual Data Scientist"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Klewer}
                            onChange={handlePamAppsChange}
                            name="Klewer"
                          />
                        }
                        label="Klewer"
                      /> */}
                      {/* <FormControlLabel
                      control={
                        <Checkbox
                          checked={GTR}
                          onChange={handlePamAppsChange}
                          name="GTR"
                        />
                      }
                      label="Guided Ticket Resolver"
                    /> */}
                      {/* <FormControlLabel
                        control={
                          <Checkbox
                            checked={Quasar}
                            onChange={handlePamAppsChange}
                            name="Quasar"
                          />
                        }
                        label="Quasar"
                      /> */}
                    </FormGroup>
                  </FormControl>
                </Grid>
              )}


            </Grid>
          </div>
        }
        history={history}
        belong={vendorName}
      ></GeneralForm>
    </>
  );
};

const MSTP = (state, ownProps) => ({
  values: state.fui.PAMForm,
  isRootExist: state.fui.isRootExist,
  configurationType: state.fui.configurationType,
  email: state.auth.userDetails.email,
  idToken: state.auth.idToken,
  externalClientData: state.fui.externalClientData,
  externalClientError: state.fui.externalClientError,
  isExternalClientLoading: state.fui.isExternalClientLoading,
  isLoading: state.fui.isLoading,
});


export default connect(MSTP, {
  checkDomainAvaliable: checkRootExisted,
  updatePAMForm: fuiRedux.updatePAMForm,
  fetchExternalClientDetails,
})(withRouter(PamInstance));
