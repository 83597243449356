import { appNameProjectId } from "./Constants/ProductIds";

/**
 * Validator for app forms
 * @author Isabel Sprengers
 * @version 1.0
 * @see Used in component: AppStepper
 */
export function FormValidate(form, configuration, formName, isRootExist) {
  const configcheck = configuration === "full" || configuration === "" ? true : false;

  const general =
    form.WBSEcode === "" ||
    form.AdminDistributionGroup === "" ||
    form.SecondaryPOC === "";

  const genralNoDeployRegion =
    configcheck ? form.Environment === "" ||
      form.SAPContractID === "" ||
      form.UsersDistributionGroup === ""
      : false;

  const atrValid =
    form.WBSEcode === "" ||
    form.SecondaryPOC === "";

  const turingValid =
    form.WBSEcode === "" ||
    form.SecondaryPOC === "";

  const atrSaaSValid =
    form.ClientName === "" ||
    form.DomainContextRoot === "no" ||
    form.DryRun === "" ||
    form.DeploymentRegion === "";

  const generalFormValid =
    // configuration === "full"
    configcheck
      ? form.UsersDistributionGroup === ""
      : false;

  const atrEnvTierValid =
    form.Environment === "Production" ?
      form.Tier === ""
      : false;

  const genwizardEnvDBTypeValid =
    form.Environment === "Production" ?
      form.DBType === ""
      : false;

  const genwizardADValid =
    form.AppsIntegration != 0 ?
      form.AdminDistributionGroup === ""
      : false;

  const taFormValid = form.TicketFeed === "" || form.TicketMonthlyVolume === "";

  const ritmFieldValid =
    configcheck ? form.RITM === "" : false;

  const ritmFieldValidBothOptions =
    form.RITM === "";

  const automationAnywhereValid =
    form.InstanceType === "" ||
    form.AAversion === "" ||
    form.NumOfControlRoomlicenses === "" ||
    form.NumOfRunnerlicenses === "" ||
    form.NumOfDeveloperlicenses === "" ||
    form.RequestNotes === "" ||
    form.LicenseUsage === "";

  const automationAnywhereLicensesValid =
    parseInt(form.NumOfControlRoomlicenses, 10) +
    parseInt(form.NumOfRunnerlicenses, 10) +
    parseInt(form.NumOfDeveloperlicenses, 10) <=
    0;

  const onPremiseServiceValid =
    form.DeploymentRegion === "" ||
    form.Environment === "" ||
    form.TentativeServiceEndDate === "" ||
    form.DailyDataVolume === "" ||
    form.InfrastructureType === "" ||
    form.LicensePurpose === "";

  const generalLicense =
    // form.DMSId === "" ||
    form.WBSEcode === "";

  const onPremLicensePurposeValid =
    form.LicensePurpose === "AiOps Asset" ?
      form.AiOpsAsset == []
      : false;

  const projectNameCheck =
    form.ProjectName === "";

  const onPremFullPlatform =
    // configuration === "full"
    configcheck
      ? form.Environment === "" ||
      form.SAPContractID === "" ||
      // form.EndToEndUId === "" ||
      form.SecondaryPOC === ""
      : false;

  const generalPlatform =
    form.DomainContextRoot === "" ||
    form.DeploymentRegion === "" ||
    form.Environment === "" ||
    form.TentativeServiceEndDate === "" ||
    // form.SAPContractID === "" ||
    // form.EndToEndUId === "" ||
    form.RequestNotes === "" ||
    isRootExist;

  const dcrCheckEventOps = configcheck ? form.DomainContextRoot === "" : false;

  const generalEventOpsPlatform =
    // form.DomainContextRoot === "" ||
    form.DeploymentRegion === "" ||
    form.Environment === "" ||
    form.TentativeServiceEndDate === "" ||
    // form.SAPContractID === "" ||
    // form.EndToEndUId === "" ||
    form.RequestNotes === "" ||
    isRootExist;

  const pamInstancePlatform =
    // form.ClientName === "" ||
    // form.SAPContractID === "" ||
    // form.EndToEndUId === "" ||
    form.DeploymentRegion === "" ||
    form.PAMPlatformType === "" ||
    form.Tier === "" ||
    form.RequestNotes === "" ||
    form.SecondaryPOC === "" ||
    (form.PAMApps &&
      form.PAMApps.length === 0 &&
      form.PAMPlatformType === "PAM Premium");

  const generalFullPlatform =
    // configuration === "full"
    configcheck
      ? form.Environment === "" ||
      form.SAPContractID === ""
      : false;

  const pamFullPlatform =
    // configuration === "full"
    configcheck
      ? form.Environment === "" ||
      form.SAPContractID === "" ||
      // form.EndToEndUId === "" ||
      form.SecondaryPOC === ""
      : false;

  const generalAtrFullPlatform =
    // configuration === "full"
    configcheck
      ? form.Environment === "" ||
      form.SAPContractID === ""
      : false;

  const generalEventOpsFullPlatform =
    // configuration === "full"
    configcheck
      ? form.Environment === "" ||
      form.SAPContractID === ""
      : false;

  const generalTuringFullPlatform =
    // configuration === "full"
    configcheck
      ?
      form.Environment === "" ||
      form.SAPContractID === ""
      : false;

  const openAICheck =
    form.OpenAI === "True" || form.EnableQuasar === "True" ?
      form.ChatGPT === "" ||
      form.OpenAiRegion === "" : false;


  const platformValid =
    generalFormValid || general || generalPlatform || generalFullPlatform;
  switch (formName) {
    case "TicketAnalysis":
      return taFormValid || generalFormValid || general || projectNameCheck;
    //Fall-through
    case "DedicatedPlatform":
    case "WindowsProvisioningPlatform":
    case "SplunkClusterPlatform":
      return generalFormValid || general || generalPlatform || generalFullPlatform || projectNameCheck;
    case "Stack360":
    case "ManagedPlatform":
      return form["appProductUId"].includes(appNameProjectId.ticketAnalysisId)
        ? platformValid || taFormValid || projectNameCheck
        : platformValid || projectNameCheck;

    case "GenWizardPlatform":
      return generalPlatform || general || genralNoDeployRegion || genwizardADValid || projectNameCheck;

    case "ADPlatform":
      return generalPlatform || general || genralNoDeployRegion || projectNameCheck;

    case "AutomaticTicketResolverSaaS":
      return atrValid || atrSaaSValid || projectNameCheck;

    case "AutomaticTicketResolver":
      return atrValid || general || generalPlatform || generalAtrFullPlatform || atrEnvTierValid || projectNameCheck ;

    case "EventOps":
      return atrValid || dcrCheckEventOps || generalEventOpsPlatform || generalEventOpsFullPlatform || projectNameCheck || general ;

    case "Turing":
      return turingValid || general || generalPlatform || generalTuringFullPlatform || atrEnvTierValid || projectNameCheck ;

    case "StormSeer":
      return atrValid || generalPlatform || generalAtrFullPlatform || projectNameCheck;

    case "PlainInstance":
      return (
        generalFormValid || general || generalPlatform || generalFullPlatform || projectNameCheck
      );
    case "AutomationAnywhereLicenses":
      return (
        generalLicense ||
        automationAnywhereValid ||
        automationAnywhereLicensesValid || projectNameCheck
      );
    case "OnPremiseService":
      return generalLicense || onPremiseServiceValid || onPremFullPlatform || onPremLicensePurposeValid || projectNameCheck;
    case "PamInstance":
      return pamInstancePlatform || pamFullPlatform || projectNameCheck;
    case "UTKMPlatform":
      return general || generalPlatform || pamFullPlatform || projectNameCheck;
    default:
      return generalFormValid || general || projectNameCheck;
  }
}
