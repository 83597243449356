import React, { useState } from "react";
import { connect } from "react-redux";
import * as fuiRedux from "../../../redux/fui/index";
import { checkWBSECode, checkMonthlyTicketVolume } from "./CommonFunctions";
import * as FormField from "./IndividualFormFields";
import PropTypes from "prop-types";
import GeneralForm from "./GeneralForm";
import { Grid } from "@material-ui/core";

//Choose a configuration type and enter into quick or full configuration components
/**
 * Ticket Analysis form
 * @author Isabel Sprengers
 * @version 2.0
 * @see Used in component: AppStepper
 */
const TicketAnalysis = ({
  configurationType,
  values, // the value would be changed by user's input
  updateTAForm, //the function to update TAForm values which is from redux
  aSelectedInstance, // the instance that selected by users in step two
  email, // user's email comes from token
  setIsTAAllValid,
  history, // the function to check whether all validations are satisfied
}) => {
  //console.log(aSelectedInstance);
  const config = configurationType === "quick";
  // check whether WBSECode and MonthlyTicketVolume are valid
  const [isWBSECodeValid, setIsWBSECodeValid] = useState(true);
  // check whether WBSECode is equal to MD & I WBSE
  const [isWBSECodeMDIValid, setIsWBSECodeMDIValid] = useState(true);
  const [isProjectNameValid, setIsProjectNameValid] = useState(true);
  const [isMonthlyTicketVolumeValid, setIsMonthlyTicketVolumeValid] = useState(
    true
  );
  const [showPassword, setShowPassword] = useState(false);

  // get user's input
  const handleChange = (e, fieldName) => {
    e.preventDefault();
    let value = e.target.value;
    updateTAForm(fieldName, value);
    if (fieldName === "WBSEcode") {
      const wbsevalue = value.toUpperCase()
      if (wbsevalue.trim() === "CBYDQ001") {
        setIsWBSECodeMDIValid(false)
      }
      setIsWBSECodeValid(checkWBSECode(value));
    }
    if (fieldName === "ProjectName") {
      if (value.length > 32 || value.length < 3) {
        setIsProjectNameValid(false)
      } else {
        setIsProjectNameValid(true)
      }
    }
    if (fieldName === "TicketMonthlyVolume") {
      if (value[0] === "0") {
        setIsMonthlyTicketVolumeValid(false);
      } else {
        setIsMonthlyTicketVolumeValid(checkMonthlyTicketVolume(value));
      }
    }
  };

  // get user's EID from email
  const createdBy = email ? email.split("@")[0] : "";

  const handleChangeMobileNo = (event, fieldName) => {
    const re = /^[0-9]{0,}$/;
    const value = event.target.value;
    re.test(value) && updateTAForm(fieldName, value);
  };

  const isAAAMTermsConditions = values.AAAMTermsConditions === "yes";
  updateTAForm("ConfigurationType", configurationType);

  updateTAForm(
    "EndToEndUId",
    aSelectedInstance.EndToEndUId === ""
      ? aSelectedInstance.DMSId
      : aSelectedInstance.EndToEndUId
  );

  // change validation status
  !isWBSECodeValid && !isWBSECodeMDIValid && !isProjectNameValid
    ? setIsTAAllValid(false)
    : setIsTAAllValid(true) || !isMonthlyTicketVolumeValid
      ? setIsTAAllValid(false)
      : setIsTAAllValid(true);
  const handleShowPassword = () => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };
  return (

    <GeneralForm
      FormId={3}
      ClientContractFields={
        <div style={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={2}
          >
            <Grid item xs={4}>
              {FormField.SAPContractIDField(handleChange, aSelectedInstance)}
            </Grid>
            <Grid item xs={4}>
              {FormField.ClientNameField(handleChange, aSelectedInstance)}
            </Grid>
            <Grid item xs={4}>
              {FormField.EndToEndUIdField(aSelectedInstance)}
            </Grid>
            <Grid item xs={4}>
              {FormField.ProjectNameField(handleChange, values, isProjectNameValid)}
            </Grid>
            <Grid item xs={4}>
              {FormField.WBSECodeField(handleChange, values, isWBSECodeValid, isWBSECodeMDIValid)}
            </Grid>
          </Grid>
        </div>
      }
      EnvironmentFields={
        <div>
          <div style={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={2}
            >
              <Grid item xs={12}>
                <p>Environment details have been successfully fetched from the selected instance details provided in the previous step.</p>

              </Grid>

            </Grid>
          </div>
        </div>
      }

      ApplicationFields={
        <div style={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={2}
          >
            <Grid item xs={6}>
              {FormField.AdminDistributionGroupField(handleChange, values)}
            </Grid>
            {!config &&
              <Grid item xs={6}>
                {FormField.UsersDistributionGroupField(handleChange, values)}
              </Grid>}
            <Grid item xs={6}>
              {FormField.PlatformTypeField(handleChange, aSelectedInstance)}
            </Grid>
            {aSelectedInstance.AaamPlatformType === "myWizard AiOps Dedicated Platform" ?
              <Grid item xs={6}>
                {FormField.TicketFeedMethodField(handleChange, values)}
              </Grid>
              :
              <Grid item xs={6}>
                {FormField.TicketFeedMethodFieldForManagedPlatform(handleChange, values)}
              </Grid>
            }
            <Grid item xs={6}>
              {FormField.MonthlyTicketVolumeField(
                handleChange,
                values,
                isMonthlyTicketVolumeValid
              )}                </Grid>

          </Grid>
        </div>
      }
      LeftColumnFields={
        <div>
        </div>
      }
      RightColumnFields={
        <div>
        </div>
      }
      history={history}
    ></GeneralForm>
  );
};

TicketAnalysis.propTypes = {
  /**
   * Users email
   */
  email: PropTypes.string,
  /**
   * The configuration type of the form
   */
  configurationType: PropTypes.string,
  /**
   * Function update TA form
   */
  updateTAForm: PropTypes.func,
  /**
   * Object containing current TA form
   */
  values: PropTypes.object,
  /**
   * Contains selected instance from the stepper
   */
  aSelectedInstance: PropTypes.object,
  /**
   * Sets true if all fields are valid for submit
   */
  setIsTAAllValid: PropTypes.func,
};

const MSTP = (state, ownProps) => ({
  configurationType: state.fui.configurationType,
  values: state.fui.TAForm,
  aSelectedInstance: state.fui.aSelectedInstance,
  email: state.auth.userDetails.email,
});

export default connect(MSTP, { updateTAForm: fuiRedux.updateTAForm })(
  TicketAnalysis
);
