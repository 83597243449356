import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
// import { updateAALForm } from "../../../redux/fui";
// import { StyledTypographyMessage } from "../FuiStyles";
import { checkWBSECode, checkLicenses } from "./CommonFunctions";
import GeneralForm from "./GeneralForm";
import * as FormField from "./IndividualFormFields";
import * as fuiRedux from "../../../redux/fui/index";
import { Grid } from "@material-ui/core";
/**
 * Automation Anywhere Licenses Form
 * @author Isabel Sprengers
 * @version 2.0
 * @see Used in component: PlatformLicenseStepper
 */
const AutomationAnywhereLicenses = ({
  history, // get route history
  values, // the value would be changed by user's input
  updateAALForm, //the function to update AALForm values which is from redux
  getNotesValue,
  notesValidations,
  setIsAllValid,
  createdBy,
}) => {
  // check whether WBSECode, ControlRoomLicenses, BotRunnerLicenses, BotCreatorLicenses are valid
  const [isWBSECodeValid, setIsWBSECodeValid] = useState(true);
  // check whether WBSECode is equal to MD & I WBSE
  const [isWBSECodeMDIValid, setIsWBSECodeMDIValid] = useState(true);
  const [isProjectNameValid, setIsProjectNameValid] = useState(true);
  const [isControlRoomLicensesValid, setIsControlRoomLicensesValid] = useState(
    true
  );
  const [isBotRunnerLicensesValid, setIsBotRunnerLicensesValid] = useState(
    true
  );
  const [isBotCreatorLicensesValid, setIsBotCreatorLicensesValid] = useState(
    true
  );

  // change validation status
  useEffect(() => {
    setIsAllValid(
      isWBSECodeValid && isWBSECodeMDIValid && isProjectNameValid &&
      isControlRoomLicensesValid &&
      isBotCreatorLicensesValid &&
      isBotRunnerLicensesValid
    );
  });

  // get user's input
  const handleChange = (event, fieldName) => {
    const value = event.target.value;
    event.preventDefault();
    updateAALForm(fieldName, value);
    if (fieldName === "WBSEcode") {
      const wbsevalue = value.toUpperCase()
      if (wbsevalue.trim() === "CBYDQ001") {
        setIsWBSECodeMDIValid(false)
      }
      setIsWBSECodeValid(checkWBSECode(value));
    }
    if (fieldName === "ProjectName") {
      if (value.length > 32 || value.length < 3) {
        setIsProjectNameValid(false)
      } else {
        setIsProjectNameValid(true)
      }
    }
    if (fieldName === "NumOfControlRoomlicenses") {
      setIsControlRoomLicensesValid(checkLicenses(value));
    }
    if (fieldName === "NumOfRunnerlicenses") {
      setIsBotRunnerLicensesValid(checkLicenses(value));
    }
    if (fieldName === "NumOfDeveloperlicenses") {
      setIsBotCreatorLicensesValid(checkLicenses(value));
    }
    if (fieldName === "RequestNotes") {
      if (value.length > 0 && value.length < 247) {
        getNotesValue({ emptyString: false, maxLength: false });
      } else if (value.length === 0) {
        getNotesValue({ emptyString: true, maxLength: false });
      } else if (value.length > 246) {
        getNotesValue({ emptyString: false, maxLength: true });
      }
    }
  };

  const isAAAMTermsConditions = values.AAAMTermsConditions === "yes";

  const handleChangeMobileNo = (event, fieldName) => {
    const re = /^[0-9]{0,}$/;
    const value = event.target.value;
    re.test(value) && updateAALForm(fieldName, value);
  };

  return (
    <GeneralForm
      NoConfig={true}
      FormId={12}
      ClientContractFields={
        <div style={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={2}
          >
            <Grid item xs={4}>
              {FormField.DMSIdField(values, handleChange)}
            </Grid>
            <Grid item xs={4}>
              {FormField.SAPContractIDPlatformFieldOthers(values, handleChange)}
            </Grid>
            <Grid item xs={4}>
              {FormField.ClientNameField(handleChange, values)}
            </Grid>
            <Grid item xs={4}>
              {FormField.ProjectNameField(handleChange, values, isProjectNameValid)}
            </Grid>
            <Grid item xs={4}>
              {FormField.WBSECodeField(handleChange, values, isWBSECodeValid, isWBSECodeMDIValid)}
            </Grid>
            <Grid item xs={12}>
              {FormField.RequestNotesField(handleChange, values, notesValidations)}
            </Grid>

          </Grid>
        </div>
      }
      EnvironmentFields={
        <div>
          <div style={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={2}
            >
              <Grid item xs={6}>
                {FormField.DeploymentRegionField(values)}
              </Grid>

            </Grid>
          </div>
        </div>
      }

      ApplicationFields={
        <div style={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={2}
          >
            <Grid item xs={4}>
              {FormField.InstanceTypeField(handleChange, values)}
            </Grid>

            {values.ChooseNewOrExistingInstance === "Existing" &&
              <Grid item xs={4}>
                {FormField.ChooseInstanceField(values)}

              </Grid>}

            <Grid item xs={4}>
              {FormField.InstanceNameField(values)}
            </Grid>

            {values.ChooseNewOrExistingInstance === "existing" &&
              <Grid item xs={4}>
                {FormField.InstanceUrlField(values)}
              </Grid>}

            <Grid item xs={4}>
              {FormField.LicenseUsageField(handleChange, values)}
            </Grid>

            <Grid item xs={4}>
              {FormField.AutomationAnywhereVersionField(handleChange, values)}
            </Grid>



            <Grid item xs={4}>
              {FormField.ControlRoomLicensesField(
                handleChange,
                values,
                isControlRoomLicensesValid
              )}</Grid>
            <Grid item xs={4}>
              {FormField.BotRunnerLicenseField(
                handleChange,
                values,
                isBotRunnerLicensesValid
              )}</Grid>
            <Grid item xs={4}>
              {FormField.BotCreatorLicenseField(
                handleChange,
                values,
                isBotCreatorLicensesValid
              )}</Grid>
          </Grid>
        </div>
      }
      history={history}
    ></GeneralForm>
  );
};

const MSTP = (state, ownProps) => ({
  email: state.auth.userDetails.email,
  values: state.fui.AALForm,
});

export default connect(MSTP, { updateAALForm: fuiRedux.updateAALForm })(
  withRouter(AutomationAnywhereLicenses)
);

